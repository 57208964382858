import styled from "styled-components";

const Button = styled.button`
  color: #fff;
  background: ${(props) => props.theme.color.light_sea_green};
  font-size: 12px;
  font-weight: 400;
  border-radius: 10px;
  padding: 3px 10px;
  border: 0;
  margin: 1em;
  transition: 0.5s background;
  cursor: pointer;
  &:first-child {
    margin: 1em 1em 1em 0;
  }
  &:last-child {
    margin: 1em 0 1em 1em;
  }
  &:hover {
    background: ${(props) => props.theme.color.dark_electric_blue};
  }
`;

export default Button;
