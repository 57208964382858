import styled from "styled-components";

const RadioInput = styled.input`
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
`;

export default RadioInput;
