import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Headline from "elements/Headline";
import InputField from "components/form/InputField";
import HelpText from "elements/HelpText";
import TextAreaField from "components/form/TextAreaField";
import IndentedSection from "elements/IndentedSection";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";
import { validateStep } from "utils/validateStep";
import RadioButtonsField from "components/form/RadioButtonsField";
import handleFields from "utils/handleFields";

function Step3({ headline, step }) {
  const { formData, jobType, validatedSteps, setValidatedSteps } = useContext(
    FormDataContext
  );
  const { apiData, labels } = useContext(ApiDataContext);

  const defaultFields = 6;
  const fields = handleFields(defaultFields, step, formData[jobType]);

  useEffect(() => {
    validateStep(
      fields,
      step,
      formData,
      validatedSteps,
      setValidatedSteps,
      jobType
    );
  }, [formData]);

  return (
    <>
      <Headline>{headline}</Headline>
      <HelpText>
        Udfyldes når det er kendt, hvilken kandidat der ønskes ansat.
      </HelpText>
      <InputField
        step={step}
        name="candidateCprnr"
        label={labels.candidateCprnr}
        value={formData[jobType]?.candidateCprnr?.value}
      />
      <HelpText>{labels.supportTexts.cpr}</HelpText>
      <InputField
        step={step}
        name="candidateName"
        label={labels.candidateName}
        value={formData[jobType]?.candidateName?.value}
      />
      <InputField
        step={step}
        name="candidateAddress"
        label={labels.address}
        value={formData[jobType]?.candidateAddress?.value}
      />
      <InputField
        step={step}
        name="candidateZipCode"
        label={labels.candidateZipCode}
        value={formData[jobType]?.candidateZipCode?.value}
      />
      <InputField
        step={step}
        name="candidateCity"
        label={labels.candidateCity}
        value={formData[jobType]?.candidateCity?.value}
      />
      <RadioButtonsField
        step={step}
        name="add_candidateNotes"
        label={labels.add_candidateNotes}
        options={apiData.add_candidateNotes}
        value={formData[jobType]?.add_candidateNotes?.value}
      />
      {formData[jobType]?.add_candidateNotes?.value ===
        "addcandidatenotes1" && (
        <IndentedSection>
          <TextAreaField
            step={step}
            name="candidateNotes"
            extendedRequired={{
              requiredField: "add_candidateNotes",
              requiredValue: "addcandidatenotes1",
            }}
            label={labels.candidateNotes}
            value={formData[jobType]?.candidateNotes?.value}
          />
        </IndentedSection>
      )}
    </>
  );
}

Step3.propTypes = {
  step: PropTypes.number,
  headline: PropTypes.string,
};

export default Step3;
