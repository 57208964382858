import React from "react";
import Modal from "components/Modal";
import PropTypes from "prop-types";
import ListOfUnvalidatedSteps from "components/ListOfUnavailableSteps";

function EmailModal({ isVisible, toggleModal, activeModal }) {
  return (
    <Modal
      isVisible={isVisible && activeModal === "email"}
      hideModal={toggleModal}
    >
      <h3>
        Du kan sende en komplet oversigt over alle felter i forklædet til en
        mailadresse
      </h3>
      <ListOfUnvalidatedSteps />
    </Modal>
  );
}

EmailModal.propTypes = {
  jobType: PropTypes.string,
  activeModal: PropTypes.string,
  isVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default EmailModal;
