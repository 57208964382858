import styled from "styled-components";

const Headline = styled.h3`
  font-size: 16px;
  font-weight: 700;
  display: table;
  min-width: 261px;
  margin: 0.5em 0;
  &:after {
    content: "";
    height: 3px;
    background: ${(props) => props.theme.color.middle_blue};
    display: block;
    margin: 7px 0 0;
  }
`;

export default Headline;
