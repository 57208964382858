import React, { useContext } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "elements/Button";
import PropTypes from "prop-types";
import useModal from "hooks/useModal";
import { FormDataContext } from "context/FormDataContext";
import PrintModal from "components/Modals/Print";
import DownloadModal from "components/Modals/Download";
import EmailModal from "components/Modals/Email";

const Header = ({ showToolBar }) => {
  const { isVisible, toggleModal, activeModal } = useModal();
  const { jobType } = useContext(FormDataContext);

  const emptyAllFields = () => {
    localStorage.clear();
    location.reload();
  };

  return (
    <header>
      <HeaderTop>
        <AppTitle>
          <Link to="/">Den gode ansættelse</Link>
        </AppTitle>
        <SubTitle>Dit digitale hjælpeværktøj</SubTitle>
      </HeaderTop>
      {showToolBar ? (
        <HeaderBottom>
          <ButtonsWrapper>
            <Button onClick={() => toggleModal("print")}>Udskriv</Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/apron";
              }}
            >
              Gå til forsiden
            </Button>
            <ResetFormBtn
              onClick={() => {
                const r = confirm(
                  "Er du sikker på at du vil nulstille hele formularen?"
                );
                if (r) emptyAllFields();
              }}
            >
              Nulstil formular
            </ResetFormBtn>
          </ButtonsWrapper>
          <Title>
            <JobTitle>{jobType}</JobTitle> ansættelse
          </Title>
          <IntroText>
            <p>
              Denne ansættelsesproces er udarbejdet med henblik på at komme de
              punkter igennem der er nødvendige for, at løn- og
              pensionsoplysninger kan indgå korrekt i indberetningen til
              lønsystemet. Det er muligt undervejs i processen at gemme de
              indtastede oplysninger, og efterfølgende vende tilbage for at
              angive de manglende oplysninger
            </p>
            <p>
              Har du spørgsmål til skemaet eller er i tvivl er du altid
              velkommen til at kontakte Stifternes løncenter for menigshedsråd
            </p>
            {jobType === "individuel" && (
              <p>
                <LinkToAgreementVacation
                  href="https://www.retsinformation.dk/eli/retsinfo/2019/10180"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ferieaftale
                </LinkToAgreementVacation>
              </p>
            )}
          </IntroText>
        </HeaderBottom>
      ) : (
        ""
      )}
      <PrintModal
        jobType={jobType}
        isVisible={isVisible}
        toggleModal={toggleModal}
        activeModal={activeModal}
      />
      <DownloadModal
        jobType={jobType}
        isVisible={isVisible}
        toggleModal={toggleModal}
        activeModal={activeModal}
      />
      <EmailModal
        jobType={jobType}
        isVisible={isVisible}
        toggleModal={toggleModal}
        activeModal={activeModal}
      />
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  jobTitle: PropTypes.string,
  showToolBar: PropTypes.bool,
};

const ResetFormBtn = styled(Button)`
  background: ${(props) => props.theme.color.dark_electric_blue};
`;

const LinkToAgreementVacation = styled.a`
  color: ${(props) => props.theme.color.dark_electric_blue};
`;

const HeaderTop = styled.div`
  background: rgba(90, 107, 126, 1);
  height: 93px;
  padding: 0 22px;
`;

const IntroText = styled.div`
  font-size: 14px;
`;

const HeaderBottom = styled.div`
  padding: 0 22px;
  @media (min-width: 1280px) {
    width: 1280px;
  }
  margin: 0 auto;
`;

const AppTitle = styled.div`
  a {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 0;
    font-weight: 400;
    padding-top: 26px;
    text-decoration: none;
    display: block;
  }
`;

const Title = styled.h1`
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
`;

const SubTitle = styled.h2`
  font-size: 12px;
  color: #ffffff;
  margin-top: 0;
  font-weight: 400;
`;

const JobTitle = styled.span`
  text-transform: capitalize;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin: 0.75em 0 0 0;
`;

export default Header;
