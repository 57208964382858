import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "elements/Label";
import { FormDataContext } from "context/FormDataContext";
import Button from "elements/Button";

function DayOffField({ name, value, step, label, options }) {
  const { updateFormData } = useContext(FormDataContext);

  const handleSelectDay = (weekday) => {
    let workSchedule = value.map((pay, i) => {
      if (weekday === i) {
        return { ...pay, dayoff: true };
      } else {
        return { ...pay, dayoff: false };
      }
    });

    updateFormData(step, workSchedule, name, false, false);
  };

  return (
    <>
      <Label>{label}</Label>
      <Toggles>
        {value.map((val, key) => (
          <ToggleButton
            key={key}
            type="button"
            name={name}
            selected={val.dayoff}
            onClick={() => handleSelectDay(key)}
          >
            {options[key].label}
          </ToggleButton>
        ))}
      </Toggles>
    </>
  );
}

const Toggles = styled.div`
  margin: 0.5em 0 1.5em 0;
`;

const ToggleButton = styled(Button)`
  margin: 0.5em 0.5em;
  &:first-child {
    margin: 0.5em 0.5em 0.75em 0;
  }
  &:last-child {
    margin: 0.5em 0.5em;
  }
  color: ${(props) =>
    props.selected ? "#fff" : props.theme.color.dark_electric_blue};
  &:hover {
    color: #fff;
  }
  background-color: ${(props) =>
    props.selected ? props.theme.color.dark_electric_blue : "#fff"};
  border: 1px solid ${(props) => props.theme.color.dark_electric_blue};
`;

Option.propTypes = {
  value: PropTypes.array,
  label: PropTypes.string,
};

DayOffField.defaultProps = {
  extendedRequired: false,
};

DayOffField.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  extendedRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      requiredField: PropTypes.string,
      requiredValue: PropTypes.string,
    }),
  ]),
};

export default DayOffField;
