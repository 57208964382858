import React, { useContext, useEffect } from "react";
import Headline from "elements/Headline";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "elements/Button";
import InputField from "components/form/InputField";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import HelpText from "elements/HelpText";
import { validateStep } from "utils/validateStep";

function Step1({ headline, step }) {
  const fields = 6;
  const {
    formData,
    jobType,
    validatedSteps,
    setValidatedSteps,
    updateEmployeeDataFromCvrApi,
  } = useContext(FormDataContext);
  const { labels } = useContext(ApiDataContext);
  const { isVisible, toggleModal, activeModal } = useModal();

  useEffect(() => {
    validateStep(
      fields,
      step,
      formData,
      validatedSteps,
      setValidatedSteps,
      jobType
    );
  }, [formData]);

  return (
    <>
      <Headline>{headline}</Headline>
      <Flex>
        <InputField
          step={step}
          name="cvr"
          label={labels.cvr}
          value={formData[jobType]?.cvr?.value}
        />
      </Flex>
      <HelpText>{labels.supportTexts.cvr}</HelpText>
      {formData[jobType]?.cvr?.isValid && (
        <Button type="button" onClick={() => updateEmployeeDataFromCvrApi()}>
          Hent oplysninger automatisk
        </Button>
      )}
      <Flex>
        <InputField
          step={step}
          name="name"
          label={labels.name}
          value={formData[jobType]?.name?.value}
        />
      </Flex>
      <HelpText>{labels.supportTexts.name}</HelpText>
      <InputField
        step={step}
        name="address"
        label={labels.address}
        value={formData[jobType]?.address?.value}
      />
      <InputField
        step={step}
        name="zipcode"
        label={labels.zipcode}
        value={formData[jobType]?.zipcode?.value}
      />
      <InputField
        step={step}
        name="city"
        label={labels.city}
        value={formData[jobType]?.city?.value}
      />
      <InputField
        step={step}
        name="workplace"
        label={labels.workplace}
        value={formData[jobType]?.workplace?.value}
        toggleModal={toggleModal}
      />
      <Modal
        isVisible={isVisible && activeModal === "workplace"}
        hideModal={toggleModal}
      >
        <h3>Arbejdssted</h3>
        <p>
          Her anføres arbejdssted samt adresse og postnr. / by fx Kirkegården,
          Skovvej 6, 4600 Køge)
        </p>
      </Modal>
    </>
  );
}

Step1.propTypes = {
  headline: PropTypes.string,
  step: PropTypes.number,
};

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default Step1;
