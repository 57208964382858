import React, { useState } from "react";
import PropTypes from "prop-types";
import { formIsValid } from "utils/handleValidation";
import { useStickyState } from "utils/handleStickyState";
import {
  defaultFormData,
  defaultFormDataGartner,
  defaultFormDataIndividuel,
} from "data/defaultData";
import axios from "axios";

export const FormDataContext = React.createContext({});

function FormDataProvider({ children }) {
  const currentJob =
    window.location.pathname.split("/")[2]?.toLowerCase() || null;
  const [jobType, setJobType] = useState(currentJob);
  const [age, setAge] = useStickyState(0, "age");
  const [formData, setFormData] = useStickyState(
    {
      graver: defaultFormData,
      gartner: defaultFormDataGartner,
      gartneriarbejder: defaultFormDataGartner,
      hk: defaultFormDataGartner,
      kirketjener: defaultFormData,
      individuel: defaultFormDataIndividuel,
    },
    "formdata"
  );
  const [errors, setErrors] = useStickyState([], "Errors");
  const [currentStep, setCurrentStep] = useStickyState(0, "currentStep");
  const [validatedSteps, setValidatedSteps] = useStickyState(
    {
      graver: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      gartner: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      gartneriarbejder: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      kirketjener: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      hk: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
      individuel: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
      },
    },
    "validatedSteps"
  );

  const [dynamicFieldsList, setDynamicFieldsList] = useStickyState(
    {
      graver: {
        secondary_workplace: 0,
      },
      gartner: {
        secondary_workplace: 0,
        wage_supplement_quality: 0,
        wage_supplement_function: 0,
      },
      gartneriarbejder: {
        secondary_workplace: 0,
        wage_supplement_quality: 0,
        wage_supplement_function: 0,
      },
      kirketjener: {
        secondary_workplace: 0,
      },
      hk: {
        secondary_workplace: 0,
        wage_supplement_quality: 0,
        wage_supplement_function: 0,
      },
      individuel: {
        secondary_workplace: 0,
        wage_supplement_quality: 0,
        wage_supplement_function: 0,
      },
    },
    "dynamicFieldsList"
  );

  const emptyFieldsCurrentStep = () => {
    const updated = { ...formData };

    const myArray = Object.entries(updated[jobType]);
    myArray.map((item) => {
      if (item[1].step === currentStep + 1) {
        if (item[0] === "secondary_workplace") {
          updated[jobType][item[0]].value = [];
        } else if (item[0] === "workSchedule") {
          updated[jobType][item[0]].value = [
            { dayoff: false, start: "", end: "", hoursTotal: 0 },
            { dayoff: false, start: "", end: "", hoursTotal: 0 },
            { dayoff: false, start: "", end: "", hoursTotal: 0 },
            { dayoff: false, start: "", end: "", hoursTotal: 0 },
            { dayoff: false, start: "", end: "", hoursTotal: 0 },
            { dayoff: false, start: "", end: "", hoursTotal: 0 },
            { dayoff: false, start: "", end: "", hoursTotal: 0 },
          ];
        } else {
          updated[jobType][item[0]].value = "";
        }
        updated[jobType][item[0]].isValid = false;
      }
    });

    setFormData(updated);

    const currentJobType = dynamicFieldsList[jobType];
    const updatedFieldList = {
      [jobType]: {
        ...currentJobType,
        secondary_workplace: 1,
      },
    };

    const updateList = { ...dynamicFieldsList, ...updatedFieldList };
    setDynamicFieldsList(updateList);
  };

  const updateDynamicFieldsList = (fieldName, modifier) => {
    const currentJobType = dynamicFieldsList[jobType];
    const updated = {
      [jobType]: {
        ...currentJobType,
        [fieldName]: dynamicFieldsList[jobType][fieldName] + modifier,
      },
    };

    const updateList = { ...dynamicFieldsList, ...updated };
    setDynamicFieldsList(updateList);
  };

  const [numberOfValidFields, setNumberOfValidFields] = useState({
    0: 0,
    1: 0,
  });

  const updateStep = (newStep) => {
    setCurrentStep(newStep);
  };

  const removeErrorMsg = (name) => {
    setErrors(errors.filter((item) => item.name !== name));
  };

  const validateField = (e) => {
    const eventName = e.target ? e.target.name : e;
    removeErrorMsg(eventName);
    const currentNumberOfValidFields = numberOfValidFields[currentStep] || 0;
    let updatedNumberOfValidFields = currentNumberOfValidFields;

    if (e.target && !formIsValid(e, updateErrorsData, calculateAge)) {
      if (currentNumberOfValidFields > 0) {
        updatedNumberOfValidFields = currentNumberOfValidFields - 1;
      }
    } else {
      updatedNumberOfValidFields = currentNumberOfValidFields + 1;
    }

    setNumberOfValidFields({
      ...numberOfValidFields,
      [currentStep]: updatedNumberOfValidFields,
    });
  };

  const updateErrorsData = (errObj) => {
    setErrors([...errors, errObj]);
  };

  function calculate_age(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  const calculateAge = (birthday) => {
    const day = birthday.substring(0, 2);
    const month = birthday.substring(2, 4) - 1;
    const year = birthday.substring(4, 6);
    let fullyear;
    if (year > 20 && year < 100) {
      fullyear = "19" + year;
    } else {
      fullyear = "20" + year;
    }
    let candidateBirthday = new Date(fullyear, month, day, 0, 0, 0);
    setAge(calculate_age(candidateBirthday));
  };

  const checkField = (e) => {
    let isValid = true;
    if (e.target && !formIsValid(e, updateErrorsData, calculateAge)) {
      isValid = false;
    }

    return isValid;
  };

  const updateNestedFields = (name, e) => {
    const fieldsImpacted = [];
    const value = e.target ? e.target.value : e;
    Object.entries(formData[jobType]).map((obj) => {
      if (
        obj[1]?.extendedRequired?.requiredField === name &&
        obj[1]?.extendedRequired?.requiredValue !== value
      ) {
        const fieldName = obj[0];
        fieldsImpacted.push(fieldName);
      }
    });

    return fieldsImpacted;
  };

  const updateFormData = (
    step,
    e,
    key,
    extendedRequired = false,
    validationRequired = true
  ) => {
    // Input or Datepicker
    const currentJobType = formData[jobType];
    const updated = {
      [jobType]: {
        ...currentJobType,
        [key]: {
          jobType,
          step,
          value: e.target ? e.target.value : e,
          isValid: validationRequired ? checkField(e) : true,
          extendedRequired,
          validationRequired,
        },
      },
    };

    let updateForm = { ...formData, ...updated };

    if (updateNestedFields(key, e).length) {
      // Reset unmounted fields
      updateNestedFields(key, e).forEach((field) => {
        updateForm[jobType][field].value = "";
        updateForm[jobType][field].isValid = false;
      });
    }

    setFormData(updateForm);
  };

  async function getCvrData(cvr) {
    const response = await axios
      .get("https://cvrapi.dk/api", {
        params: { search: cvr, country: "dk" },
      })
      .catch(() => {
        return false;
      });
    if (response.data) {
      return response.data;
    } else {
      alert(
        "Der kunne ikke findes nogle oplysninger ud fra det indtastede CVR nr."
      );
    }
  }

  const updateEmployeeDataFromCvrApi = () => {
    const currentJobType = formData[jobType];
    const step = 1;
    const extendedRequired = false;
    const cvr = formData[jobType].cvr.value;

    getCvrData(cvr).then((data) => {
      const updated = {
        [jobType]: {
          ...currentJobType,
          name: {
            jobType,
            step,
            value: data?.name,
            isValid: true,
            extendedRequired,
          },
          address: {
            jobType,
            step,
            value: data?.address,
            isValid: true,
            extendedRequired,
          },
          city: {
            jobType,
            step,
            value: data?.city,
            isValid: true,
            extendedRequired,
          },
          zipcode: {
            jobType,
            step,
            value: data?.zipcode,
            isValid: true,
            extendedRequired,
          },
        },
      };
      const updateForm = { ...formData, ...updated };
      setFormData(updateForm);
    });
  };

  // useEffect(() => {
  //   updateStep(0);
  // }, [jobType]);

  return (
    <FormDataContext.Provider
      value={{
        formData,
        age,
        updateFormData,
        errors,
        updateStep,
        numberOfValidFields,
        validateField,
        currentStep,
        validatedSteps,
        setValidatedSteps,
        jobType,
        setJobType,
        dynamicFieldsList,
        updateDynamicFieldsList,
        emptyFieldsCurrentStep,
        updateEmployeeDataFromCvrApi,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
}

FormDataProvider.propTypes = {
  children: PropTypes.object,
};

export default FormDataProvider;
