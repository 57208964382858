import React, { useContext } from "react";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";

import styled from "styled-components";
import NavItem from "components/NavItem";

function StepNavigation() {
  const { currentStep, jobType } = useContext(FormDataContext);
  const { steps } = useContext(ApiDataContext);

  const stepTitles = steps[jobType]?.names ?? steps.default.names;

  return (
    <StepNavigationWrapper>
      {stepTitles.map(function (step, index) {
        const active = currentStep === index;
        if (step[1]) {
          return (
            <NavItem
              key={index}
              isActive={active}
              stepNumber={index + 1}
              stepLabel={step[2]}
              title={step[0]}
            />
          );
        }
      })}
    </StepNavigationWrapper>
  );
}

const StepNavigationWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0 0 2em 0;
  max-width: 1280px;
  padding: 0;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) calc(50% - 1px),
    ${(props) => props.theme.color.powder_blue} calc(50%),
    rgba(0, 0, 0, 0) calc(50% + 1px)
  );
  background-position: 0 -12px;
`;

export default StepNavigation;
