import styled from "styled-components";

const Select = styled.select`
  font-size: 12px;
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.color.powder_blue};
  box-sizing: border-box;
  border-radius: 8px;
  height: 32px;
  padding: 0 0.5em;
  min-width: 261px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      ${(props) => props.theme.color.powder_blue} 50%
    ),
    linear-gradient(
      135deg,
      ${(props) => props.theme.color.powder_blue} 50%,
      transparent 50%
    );
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  &:focus {
    outline: 0;
  }
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

export default Select;
