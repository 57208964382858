import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "elements/Label";
import SubLabel from "elements/SubLabel";
import { FormDataContext } from "context/FormDataContext";
import Button from "elements/Button";

function DaysOffField({ name, value, step, label, options }) {
  const { updateFormData } = useContext(FormDataContext);

  const handleSelectDay = (weekday) => {
    let workSchedule = value.map((day, i) => {
      // If selected day is a day off, remove it as a day off
      if (weekday === i) {
        return { ...day, dayoff: !day.dayoff };
      } else if (day.dayoff === true) {
        return { ...day, dayoff: true };
      } else {
        return { ...day, dayoff: false };
      }
    });
    console.table(workSchedule);
    updateFormData(step, workSchedule, name, false, false);
  };

  const handleErrorMsg = (type) => {
    if (type === 1) {
      alert("Du kan max vælge 2 fridage, fjern en af de valgte først");
    } else {
      alert("Du skal vælge minimum én fridag");
    }
  };

  const days = value.filter((val) => val.dayoff === true);
  const daysOff = days.length;

  return (
    <>
      <Label>{label}</Label>
      <SubLabel>
        Hvis du vil vælge andre fridage, skal du først fravælge en af de
        allerede valgte
      </SubLabel>
      <Toggles>
        {value.map((val, key) => {
          if (daysOff === 2 && !val.dayoff) {
            return (
              <ToggleButtonOff
                key={key}
                type="button"
                name={name}
                selected={val.dayoff}
                onClick={() => handleErrorMsg(1)}
              >
                {options[key].label}
              </ToggleButtonOff>
            );
          } else if (daysOff === 1 && val.dayoff) {
            return (
              <ToggleButton
                key={key}
                type="button"
                name={name}
                selected={val.dayoff}
                onClick={() => handleErrorMsg(2)}
              >
                {options[key].label}
              </ToggleButton>
            );
          } else {
            return (
              <ToggleButton
                key={key}
                type="button"
                name={name}
                selected={val.dayoff}
                onClick={() => handleSelectDay(key)}
              >
                {options[key].label}
              </ToggleButton>
            );
          }
        })}
      </Toggles>
    </>
  );
}

const Toggles = styled.div`
  margin: 0.5em 0 1.5em 0;
`;

const ToggleButton = styled(Button)`
  margin: 0.5em 0.5em;
  &:first-child {
    margin: 0.5em 0.5em 0.75em 0;
  }
  &:last-child {
    margin: 0.5em 0.5em;
  }
  color: ${(props) =>
    props.selected ? "#fff" : props.theme.color.dark_electric_blue};
  &:hover {
    color: #fff;
  }
  background-color: ${(props) =>
    props.selected ? props.theme.color.dark_electric_blue : "#fff"};
  border: 1px solid ${(props) => props.theme.color.dark_electric_blue};
`;

const ToggleButtonOff = styled(ToggleButton)`
  background-color: #f3f3f3;
  color: #ccc;
  border: 1px solid #ccc;
  &:hover {
    background-color: #f3f3f3;
    color: #ccc;
    border: 1px solid #ccc;
  }
`;

Option.propTypes = {
  value: PropTypes.array,
  label: PropTypes.string,
};

DaysOffField.defaultProps = {
  extendedRequired: false,
};

DaysOffField.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  extendedRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      requiredField: PropTypes.string,
      requiredValue: PropTypes.string,
    }),
  ]),
};

export default DaysOffField;
