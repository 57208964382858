import { useState } from "react";

const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [activeModal, setActiveModal] = useState();

  function toggleModal(modalName) {
    setActiveModal(modalName);
    setIsVisible(!isVisible);
  }
  return {
    isVisible,
    activeModal,
    toggleModal,
  };
};

export default useModal;
