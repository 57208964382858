export const formIsValid = (
  { target: { name, value } },
  updateErrorsData,
  calculateAge
) => {
  let isValid = true;

  // CVR
  if (name === "cvr" && (value.match(/[A-Za-z]/g) || value.length !== 8)) {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldigt CVR, kun tal og præcis 8 cifre",
    };
    updateErrorsData(errObj);
  }

  if (name === "zipcode" && (value.match(/[A-Za-z]/g) || value.length !== 4)) {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldigt postnummer, kun tal og præcis 4 cifre",
    };
    updateErrorsData(errObj);
  }

  // CPR
  if (
    name === "candidateCprnr" &&
    (value.match(/[A-Za-z!@#$%^&*)(+=._]/g) ||
      value.length !== 11 ||
      value.indexOf("-") !== 6 ||
      value.indexOf("-", 7) > -1)
  ) {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldigt CPR nr., skal følge formatet 000000-0000",
    };
    updateErrorsData(errObj);
  } else {
    calculateAge(value.split("-")[0]);
  }

  // Navn
  if (name === "name" && (value.match(/\d+/g) || value === "")) {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldig angivelse af navn",
    };
    updateErrorsData(errObj);
  }

  // Address
  if (name === "address" && value === "") {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldig angivelse af adresse",
    };
    updateErrorsData(errObj);
  }

  // City
  if (name === "city" && value === "") {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldig angivelse af by",
    };
    updateErrorsData(errObj);
  }

  // Arbejdssted
  // Begrundelse for tidsbegrænsning
  // Kandidat noter
  if (
    (name === "workplace" ||
      name === "reason_for_nonlastingemployment" ||
      name === "contactName" ||
      name === "contactAddress" ||
      name === "contactPhoneNumber" ||
      name === "dealsNotes" ||
      name === "other_pension" ||
      name === "agreed_pay" ||
      name === "pay_scale_step" ||
      name === "pay_scale_wage" ||
      name === "agreed_pay_non_regulated" ||
      name === "agreed_wage_1" ||
      name === "agreed_wage_2" ||
      name === "monthly_pay_step_hk_grp1" ||
      name === "monthly_pay_step_hk_grp2" ||
      name === "monthly_pay_step_hk_grp3" ||
      name === "candidateNotes") &&
    value === ""
  ) {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Dette felt skal udfyldes",
    };
    updateErrorsData(errObj);
  }

  // Ugentlige timer
  if (
    name === "weekly_hours" &&
    (value.match(/-|[A-ÆØÅa-æøå]/g) || value === "0" || value == "")
  ) {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldig værdi, kun tal og over 0",
    };
    updateErrorsData(errObj);
  }

  // Stillingstype
  if (name === "positiontype" && value === "") {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldig stillingstype",
    };
    updateErrorsData(errObj);
  }

  // Ansættelsesmyndighed
  if (name === "authority" && value === "") {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldig ansættelsesmyndighed",
    };
    updateErrorsData(errObj);
  }

  // Tidsbegrænset til begivenhed
  // Tidsbegrænset til dato
  if (name === "limited_to_event" && value === "") {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Dette felt skal udfyldes hvis der ikke er valgt en dato",
    };
    updateErrorsData(errObj);
  }

  // Ansættelsestidspunkt
  if (name === "starttime_of_employment" && value === "") {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldigt ansættelsestidspunkt",
    };
    updateErrorsData(errObj);
  }

  // Løn
  if (
    name === "pay" &&
    (value.match(/-|[A-ÆØÅa-æøå]/g) || value === "0" || value == "")
  ) {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldigt beløb",
    };
    updateErrorsData(errObj);
  }

  // Lønmodtagers navn
  if (name === "candidateName" && value === "") {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldigt navn",
    };
    updateErrorsData(errObj);
  }

  // Lønmodtagers adresse
  if (name === "candidateAddress" && value === "") {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldig angivelse af adresse",
    };
    updateErrorsData(errObj);
  }

  // Lønmodtagers postnr
  if (
    name === "candidateZipCode" &&
    (value.match(/[A-Za-z]/g) || value.length !== 4)
  ) {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldig angivelse af postnummer",
    };
    updateErrorsData(errObj);
  }

  // Lønmodtagers postnr
  if (name === "candidateCity" && (value.match(/\d+/g) || value === "")) {
    isValid = false;
    const errObj = {
      name: name,
      msg: "Ugyldig angivelse af by",
    };
    updateErrorsData(errObj);
  }

  return isValid;
};
