import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "elements/Label";
import ErrorMessage from "elements/ErrorMessage";
import TextArea from "elements/TextArea";
import { FormDataContext } from "context/FormDataContext";

const TextAreaFieldWrapper = styled.div`
  padding: 0.46875em 0;
`;

function TextAreaField({ extendedRequired, name, label, value, step }) {
  const { updateFormData, errors, validateField } = useContext(FormDataContext);

  const handleOnChange = (e) => {
    updateFormData(step, e, name, extendedRequired);
    validateField(e);
  };

  const error = errors.filter((err) => err.name === name);

  return (
    <TextAreaFieldWrapper>
      <Label htmlFor={name}>{label}</Label>
      <TextArea
        type="textarea"
        id={name}
        name={name}
        value={value}
        onBlur={(e) => validateField(e)}
        onChange={(e) => handleOnChange(e)}
      />
      {error.length > 0 && <ErrorMessage>{error[0].msg}</ErrorMessage>}
    </TextAreaFieldWrapper>
  );
}

TextAreaField.defaultProps = {
  extendedRequired: false,
};

TextAreaField.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  extendedRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      requiredField: PropTypes.string,
      requiredValue: PropTypes.string,
    }),
  ]),
};

export default TextAreaField;
