import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "elements/Label";
import RadioInput from "elements/RadioInput";
import { FormDataContext } from "context/FormDataContext";
import { clearConfigCache } from "prettier";

const RadioButtonFieldWrapper = styled.div`
  padding: 0.46875em 0;
`;

const RadioLabel = styled.label`
  font-size: 14px;
  color: ${(props) => props.theme.color.dark_electric_blue};
  padding: 0 0 0 0.25em;
`;

const RadioWrapper = styled.div`
  display: flex;
  margin: 0.25em 0 0 0;
`;

function RadioBtn({ label, value, name }) {
  const { formData, jobType } = useContext(FormDataContext);

  return (
    <RadioWrapper>
      <RadioInput
        type="radio"
        value={value}
        name={name}
        id={value}
        defaultChecked={formData[jobType]?.[name]?.value === value}
      />
      <RadioLabel htmlFor={value}>{label}</RadioLabel>
    </RadioWrapper>
  );
}

function RadioButtonsField({
  extendedRequired,
  name,
  label,
  options,
  value,
  step,
}) {
  const { updateFormData } = useContext(FormDataContext);
  const myRadioButtons = options.map((d, i) => (
    <RadioBtn label={d.label} value={d.value} name={name} key={i} />
  ));

  return (
    <RadioButtonFieldWrapper>
      <Label>{label}</Label>
      <div
        onChange={(e) => updateFormData(step, e, name, extendedRequired)}
        value={value}
      >
        {myRadioButtons}
      </div>
    </RadioButtonFieldWrapper>
  );
}

RadioButtonsField.defaultProps = {
  extendedRequired: false,
};

RadioButtonsField.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  extendedRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      requiredField: PropTypes.string,
      requiredValue: PropTypes.string,
    }),
  ]),
};

RadioBtn.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
};

export default RadioButtonsField;
