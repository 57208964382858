import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "elements/Label";
import ErrorMessage from "elements/ErrorMessage";
import HelpBtn from "elements/HelpBtn";
import Input from "elements/Input";
import { FormDataContext } from "context/FormDataContext";

function WagesInputField({ extendedRequired, name, value = [], step }) {
  const {
    updateFormData,
    errors,
    validateField,
    dynamicFieldsList,
    updateDynamicFieldsList,
    jobType,
  } = useContext(FormDataContext);

  const handleAddClick = () => {
    updateDynamicFieldsList(name, 1);
    value.push(["", ""]);
    updateFormData(step, value, name, extendedRequired);
  };

  const handleRemoveClick = (i) => {
    if (fields.length <= 1) return;

    // Remove 1 item from array at position i
    value.splice(i, 1);
    updateFormData(step, value, name, extendedRequired);
    updateDynamicFieldsList(name, -1);
  };

  const updateField = (e, step, extendedRequired, i, j) => {
    const newValue = e.target.value;
    // If field exists
    if (i < value.length) {
      value[i][j] = newValue;
    } else {
      value.push(["", ""]);
      value[i][j] = newValue;
    }
    updateFormData(step, value, name, extendedRequired);
  };

  const getValue = (i, j) => {
    return value[i] !== undefined ? value[i][j] : null;
  };

  const fields = [];

  for (let i = 0; i < dynamicFieldsList[jobType]?.[name]; i++) {
    fields.push(
      <FieldWrapper key={i}>
        <Flex>
          <div>
            <Label>Navn på tillæg</Label>
            <SmallerInput
              onChange={(e) => updateField(e, step, extendedRequired, i, 0)}
              onBlur={(e) => validateField(e)}
              value={getValue(i, 0)}
            />
          </div>
          <div>
            <Label>Beløb</Label>
            <SmallerInput
              onChange={(e) => updateField(e, step, extendedRequired, i, 1)}
              onBlur={(e) => validateField(e)}
              value={getValue(i, 1)}
            />
          </div>

          <HelpBtnAlt type="button" onClick={() => handleRemoveClick(i)}>
            -
          </HelpBtnAlt>
          <HelpBtnAlt type="button" onClick={handleAddClick}>
            +
          </HelpBtnAlt>
        </Flex>
      </FieldWrapper>
    );
  }

  return (
    <>
      <DynamicInputFieldWrapper>
        {fields}
        {errors[name] && <ErrorMessage>{errors[name]}</ErrorMessage>}
      </DynamicInputFieldWrapper>
    </>
  );
}

const HelpBtnAlt = styled(HelpBtn)`
  transform: translateY(0.75em);
`;

const SmallerInput = styled(Input)`
  margin: 0 1em 0 0;
`;

const FieldWrapper = styled.div`
  margin: 1em 0;
`;

const DynamicInputFieldWrapper = styled.div`
  padding: 0.46875em 0;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

Option.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

WagesInputField.defaultProps = {
  extendedRequired: false,
};

WagesInputField.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  extendedRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      requiredField: PropTypes.string,
      requiredValue: PropTypes.string,
    }),
  ]),
};

export default WagesInputField;
