import styled from "styled-components";

const SupportText = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 1em 0;
  font-style: oblique;
  color: ${(props) => props.theme.color.dark_electric_blue};
`;

export default SupportText;
