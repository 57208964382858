import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "elements/Label";
import ErrorMessage from "elements/ErrorMessage";
import HelpBtn from "elements/HelpBtn";
import Input from "elements/Input";
import { FormDataContext } from "context/FormDataContext";

const InputFieldWrapper = styled.div`
  padding: 0.46875em 0;
`;

function InputField({
  extendedRequired,
  name,
  label,
  value,
  step,
  toggleModal,
  validationRequired,
}) {
  const { updateFormData, errors, validateField } = useContext(FormDataContext);

  const handleOnChange = (e) => {
    updateFormData(step, e, name, extendedRequired, validationRequired);
    validateField(e);
  };

  const handleOnBlur = (e) => {
    validateField(e);
  };

  const error = errors.filter((err) => err.name === name);

  return (
    <InputFieldWrapper>
      <Label htmlFor={name}>{label}</Label>
      <Flex>
        <Input
          type="text"
          id={name}
          name={name}
          onChange={(e) => handleOnChange(e)}
          onBlur={(e) => handleOnBlur(e)}
          value={value}
        />
        {toggleModal && <HelpBtn onClick={() => toggleModal(name)}>?</HelpBtn>}
      </Flex>
      {error.length > 0 && <ErrorMessage>{error[0].msg}</ErrorMessage>}
    </InputFieldWrapper>
  );
}

const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

InputField.defaultProps = {
  extendedRequired: false,
};

InputField.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  toggleModal: PropTypes.func,
  validationRequired: PropTypes.bool,
  extendedRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      requiredField: PropTypes.string,
      requiredValue: PropTypes.string,
    }),
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default InputField;
