import React, { useContext } from "react";
import { FormDataContext } from "context/FormDataContext";
import PropTypes from "prop-types";
import styled from "styled-components";

function NavItem({ title, stepNumber, stepLabel, isActive }) {
  const { updateStep, validatedSteps, jobType } = useContext(FormDataContext);

  const handleUpdateStep = (step) => {
    updateStep(step);
  };

  return (
    <NavItemWrapper onClick={() => handleUpdateStep(stepNumber - 1)}>
      <NavItemNumberWrapper>
        <NavItemNumber
          isActive={isActive}
          completed={validatedSteps[jobType]?.[stepNumber]}
        >
          {validatedSteps[jobType]?.[stepNumber] ? <Svg /> : stepLabel}
        </NavItemNumber>
      </NavItemNumberWrapper>
      <NavItemTitle isActive={isActive}>{title}</NavItemTitle>
    </NavItemWrapper>
  );
}

NavItem.propTypes = {
  title: PropTypes.string,
  stepNumber: PropTypes.number,
  stepLabel: PropTypes.number,
  isActive: PropTypes.bool,
};

const Svg = styled.div`
  background: url("/stepcomplete.svg");
  width: 16px;
  height: 16px;
`;

const NavItemNumberWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavItemNumber = styled.div`
  border-radius: 100px;
  font-size: 12px;
  background: #ffffff;
  font-weight: 700;
  color: ${(props) => props.theme.color.dark_electric_blue};
  border: ${(props) =>
    props.isActive
      ? "3px solid " + props.theme.color.dark_electric_blue
      : "1px solid " + props.theme.color.powder_blue};
  width: ${(props) => (props.isActive ? "38px" : "28px")};
  height: ${(props) => (props.isActive ? "38px" : "28px")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const NavItemTitle = styled.div`
  font-size: 12px;
  list-style: none;
  margin: 0.75em 0 0 0;
  color: ${(props) => props.theme.color.dark_electric_blue};
  font-weight: ${(props) => (props.isActive ? 700 : 400)};
  text-align: center;
  box-sizing: border-box;
  width: 150px;
`;

const NavItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 2.25em; */
  &:first-child {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 50%
    );
  }
  &:last-child {
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 0) 50%
    );
  }
`;

export default NavItem;
