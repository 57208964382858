import React, { useContext } from "react";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";
import Label from "elements/Label";
import styled from "styled-components";
import PropTypes from "prop-types";
import HelpText from "elements/HelpText";
import WorkScheduleField from "components/form/WorkScheduleField";

function getLabel(fieldValue, options) {
  const index = options?.findIndex((e) => e.value == fieldValue);
  if (fieldValue) return options[index].label;
}

function getValuesFromArray(fieldValue) {
  const values = [];
  fieldValue.map((x, key) => {
    values.push(
      <div key={key}>
        {x[0]}, {x[1]} {x[2]}
        <div>{x[3]}</div>
      </div>
    );
  });
  return values;
}

function formatDate(date) {
  return (
    date.getDate() +
    "." +
    (parseInt(date.getMonth()) + 1) +
    "." +
    date.getFullYear()
  );
}

function getValue(fieldValue, isMultiChoice, options, isDate) {
  let value = fieldValue;
  if (isMultiChoice) {
    value = getLabel(fieldValue, options);
  }
  if (isDate && fieldValue) {
    const myDate = new Date(fieldValue);
    value = formatDate(myDate);
  }
  return value;
}

function Field({
  fieldName,
  isMultiChoice,
  isTextArea,
  isDate,
  small,
  labelBasedOnJobType,
}) {
  const { formData, jobType } = useContext(FormDataContext);
  const { apiData, labels } = useContext(ApiDataContext);

  const fieldValue = formData[jobType]?.[fieldName]?.value;
  const options = apiData[fieldName];

  const value = Array.isArray(fieldValue)
    ? getValuesFromArray(fieldValue)
    : getValue(fieldValue, isMultiChoice, options, isDate);

  let label;
  if (labelBasedOnJobType) {
    label = labels[fieldName][jobType] ?? labels[fieldName].default;
  } else {
    label = labels[fieldName];
  }

  return (
    <FieldWrapper>
      <LabelMaxWidth>{label}</LabelMaxWidth>
      <Value isTextArea={isTextArea} small={small}>
        {value}
      </Value>
    </FieldWrapper>
  );
}

const LabelMaxWidth = styled(Label)`
  max-width: 450px;
`;

function Schedule() {
  const { apiData, labels } = useContext(ApiDataContext);
  const { formData, jobType } = useContext(FormDataContext);
  return (
    <FieldWrapper>
      {formData[jobType]?.workschedule_type?.value !== "" ? (
        <LabelMaxWidth>
          {formData[jobType]?.weekly_hours?.value >= 37
            ? labels.supportTexts.fullTime[
                formData[jobType]?.workschedule_type?.value
              ] ?? labels.supportTexts.fullTime.default
            : labels.supportTexts.parttime[
                formData[jobType]?.workschedule_type?.value
              ] ?? labels.supportTexts.parttime.default}
        </LabelMaxWidth>
      ) : (
        <LabelMaxWidth>
          {formData[jobType]?.weekly_hours?.value >= 37
            ? labels.supportTexts.fullTime[jobType] ??
              labels.supportTexts.fullTime.default
            : labels.supportTexts.parttime[jobType] ??
              labels.supportTexts.parttime.default}
        </LabelMaxWidth>
      )}
      <WorkScheduleField
        name="workSchedule"
        printversion
        options={apiData.days}
        value={formData[jobType]?.workSchedule?.value}
      />
    </FieldWrapper>
  );
}

function PrintPreview() {
  const { labels } = useContext(ApiDataContext);
  const { jobType } = useContext(FormDataContext);
  const currentDate = formatDate(new Date());
  return (
    <PrintPreviewWrapper>
      <Title>Udskrift af forklæde</Title>
      <SubTitle>
        {jobType} - {currentDate}
      </SubTitle>
      <button onClick={() => window.print()}>Udskriv</button>
      <Header>{labels.steps[0].header}</Header>
      <Field fieldName="cvr" />
      <Field fieldName="name" />
      <Field fieldName="address" />
      <Field fieldName="zipcode" />
      <Field fieldName="city" />
      <Field fieldName="workplace" />

      <Header>{labels.steps[1].header}</Header>
      <Field fieldName="starttime_of_employment" isDate />
      <Field fieldName="employment_lasting" isMultiChoice />

      <Field fieldName="reason_for_nonlastingemployment" isTextArea />
      <Field fieldName="limited_to_event" />
      <Field fieldName="limited_to_date" isDate />

      {jobType === "individuel" && (
        <>
          <Field fieldName="remuneration" isMultiChoice />
          <Field fieldName="regulated_pay" isMultiChoice />
          <Field fieldName="agreed_pay" />
          <Field fieldName="pay_level" />
          <Field fieldName="pay_scale" isMultiChoice />
          <Field fieldName="pay_scale_step" />
          <Field fieldName="pay_scale_wage" />
          <Field fieldName="agreed_pay_non_regulated" />
          <Field fieldName="wages_besides_pay" />
          <Label legend>Tillæg 1</Label>
          <Field fieldName="regulated_wage_1" isMultiChoice />
          <Field fieldName="agreed_wage_1" />
          <Field fieldName="pay_level_wage_1" />
          <Label legend>Tillæg 2</Label>
          <Field fieldName="regulated_wage_2" isMultiChoice />
          <Field fieldName="agreed_wage_2" />
          <Field fieldName="pay_level_wage_2" />
        </>
      )}

      <Field fieldName="weekly_hours" labelBasedOnJobType />
      <Field fieldName="trialAgreement" isMultiChoice />

      {jobType !== "individuel" && (
        <Field fieldName="official_pension" labelBasedOnJobType isMultiChoice />
      )}
      {(jobType === "graver" || jobType === "kirketjener") && (
        <Field fieldName="hired_thirtydays" isMultiChoice labelBasedOnJobType />
      )}
      {jobType === "graver" && <Field fieldName="amu" isMultiChoice />}
      {(jobType === "graver" || jobType === "kirketjener") && (
        <Field fieldName="pay" />
      )}
      {(jobType === "gartner" ||
        jobType === "gartnerimedarbejder" ||
        jobType === "hk") && (
        <Field fieldName="employment_location" isMultiChoice />
      )}
      {jobType === "hk" && (
        <>
          <Field fieldName="monthly_pay_step_hk_grp1" isMultiChoice />
          <Field fieldName="advancement_date_step2" isDate />
          <Field fieldName="monthly_pay_step_hk_grp2" isMultiChoice />
          <Field fieldName="monthly_pay_step_hk_grp3" isMultiChoice />
        </>
      )}
      {(jobType === "gartner" || jobType === "gartnerimedarbejder") && (
        <Field fieldName="monthly_pay_step" isMultiChoice />
      )}
      {(jobType === "gartner" ||
        jobType === "gartnerimedarbejder" ||
        jobType === "hk") && (
        <>
          <Field fieldName="wage_supplement_quality" />
          <Field fieldName="wage_supplement_function" />
        </>
      )}

      <Header>{labels.steps[2].header}</Header>
      <Field fieldName="candidateCprnr" />
      <Field fieldName="candidateName" />
      <Field fieldName="candidateAddress" />
      <Field fieldName="candidateZipCode" />
      <Field fieldName="candidateCity" />
      <Field fieldName="candidateNotes" isTextArea />

      {jobType !== "individuel" && (
        <>
          <Header>{labels.steps[3].header}</Header>
          <Field fieldName="age70" isMultiChoice />
          <Field fieldName="handlePension" isMultiChoice />
          <Field fieldName="other_pension" />
          <Field fieldName="age25_atleast4years" isMultiChoice />
          <Label>Hvor længe har du været ansat i branchen?</Label>
          <Field fieldName="years_in_business" />
          <Field fieldName="months_in_business" />
          <Field fieldName="age20_atleast9months" isMultiChoice />
          <Field fieldName="age20_payed_pension" isMultiChoice />
          <Label addTopMargin>Gruppeliv</Label>
          <StartAndEndDate>
            <Field fieldName="pension_gruppeliv_startdate" isDate small />
            <Field fieldName="pension_gruppeliv_enddate" isDate small />
          </StartAndEndDate>
          <Label addTopMargin>Minipension</Label>
          <StartAndEndDate>
            <Field fieldName="pension_mini_startdate" isDate small />
            <Field fieldName="pension_mini_enddate" isDate small />
          </StartAndEndDate>
          <Field fieldName="date_full_pension" isDate />
        </>
      )}

      {/* Remove step 5 - Om stillingen for these jobtypes */}
      {jobType !== "individuel" &&
        jobType !== "gartner" &&
        jobType !== "gartneriarbejder" &&
        jobType !== "hk" && (
          <>
            <Header>{labels.steps[4].header}</Header>
            <Field
              fieldName="geographic_obligation"
              isMultiChoice
              labelBasedOnJobType
            />
            <Field fieldName="secondary_workplace" />
            <Field fieldName="professional_obligation" labelBasedOnJobType />
            <Field fieldName="availability_surcharge" isMultiChoice />
            <Field fieldName="availability_surcharge_type" isMultiChoice />
            {(jobType === "graver" || jobType === "kirketjener") && (
              <HelpText>
                {labels.supportTexts.availability_surcharge[jobType]}
              </HelpText>
            )}
          </>
        )}

      <Header>{labels.steps[5].header}</Header>
      <Field fieldName="employee_handbook" isMultiChoice />
      <Label addTopMargin>Aktuel kontaktperson</Label>
      <Field fieldName="contactName" />
      <Field fieldName="contactPhoneNumber" />
      <Field fieldName="contactEmail" />
      <Schedule />
      <Field fieldName="workScheduleAdditionalNotes" isTextArea />
      <Field fieldName="dealsNotes" isTextArea />
      <button onClick={() => window.print()}>Udskriv</button>
    </PrintPreviewWrapper>
  );
}

const PrintPreviewWrapper = styled.div`
  margin: 2em;
`;

const FieldWrapper = styled.div`
  margin: 1em 0;
`;

const Title = styled.h1`
  margin: 0;
`;

const SubTitle = styled.h2`
  margin-top: 0;
  font-weight: normal;
  text-transform: capitalize;
`;

const Header = styled.h2`
  font-size: 1.25em;
  margin-top: 2em;
`;

const StartAndEndDate = styled.div`
  display: flex;
  gap: 1em;
`;

const Value = styled.div`
  border: 1px solid #ccc;
  border-left: ${(props) => (props.isTextArea ? "1" : "none")};
  border-right: ${(props) => (props.isTextArea ? "1" : "none")};
  border-top: ${(props) => (props.isTextArea ? "1" : "none")};
  padding: ${(props) => (props.isTextArea ? "0.5em" : "0.5em 0 .125em 0")};
  width: ${(props) => (props.small ? "200px" : "450px")};
  font-size: 0.95em;
  min-height: ${(props) => (props.isTextArea ? "100px" : "20px")};
`;

Field.propTypes = {
  fieldName: PropTypes.string,
  isMultiChoice: PropTypes.bool,
  isTextArea: PropTypes.bool,
  isDate: PropTypes.bool,
  small: PropTypes.bool,
  labelBasedOnJobType: PropTypes.bool,
};

export default PrintPreview;
