import * as React from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

const Modal = ({ isVisible, hideModal, children }) => {
  return isVisible
    ? createPortal(
        <>
          <ModalOverlay />
          <ModalWrapper>
            <ModalContent>
              <ModalText>{children}</ModalText>
              <ModalCloseBtn onClick={hideModal}>&times;</ModalCloseBtn>
            </ModalContent>
          </ModalWrapper>
        </>,
        document.body
      )
    : null;
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isVisible: PropTypes.bool,
  hideModal: PropTypes.func,
};

const ModalCloseBtn = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: 0;
  background: none;
  font-size: 20px;
`;

const ModalOverlay = styled.div`
  background-color: #999999;
  height: 100vh;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 500;
`;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
  top: 0;
  left: 0;
  outline: 0;
`;

const ModalContent = styled.div`
  width: 500px;
  height: 400px;
  background: #ffffff;
  border: 1px solid #ccc;
  padding: 1em;
  position: relative;
  z-index: 1001;
  border-bottom: 4px solid ${(props) => props.theme.color.light_sea_green};
`;

const ModalText = styled.div`
  font-weight: 400;
  padding: 1em 0 0 0;
`;

export default Modal;
