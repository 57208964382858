import styled from "styled-components";

const Label = styled.label`
  font-size: 14px;
  font-weight: ${(props) => (props.legend ? "700" : "500")};
  color: ${(props) => props.theme.color.dark_electric_blue};
  display: block;
  margin-top: ${(props) => (props.margintop ? "2em" : "")};
`;

export default Label;
