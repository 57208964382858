import React from "react";
import PropTypes from "prop-types";

export const ApiDataContext = React.createContext({});

function ApiDataProvider({ children }) {
  const labels = {
    cvr: "CVR. nr.",
    name: "Arbejdsgivers navn",
    address: "Adresse",
    city: "By",
    zipcode: "Postnr.",
    workplace: "Primært arbejdssted",
    starttime_of_employment: "Ansættelsestidspunkt",
    employment_lasting: "Ansættelsen er varig",
    reason_for_nonlastingemployment: "Begrundelse for tidsbegrænsning",
    employment_lasting_reason: "Begrundelse for tidsbegrænsning",
    limited_to_event: "Tidsbegrænset til begivenhed",
    limited_to_date: "Tidsbegrænset til dato",
    weekly_hours: {
      kirketjener: "Den ugentlige arbejdstid (hele timer)",
      default: "Den ugentlige arbejdstid",
    },
    remuneration: "Hvordan er aflønningen for ansættelsen?",
    agreed_pay: "Angiv den aftalte time eller månedsløn i nutids kr.",
    regulated_pay:
      "Skal lønnen reguleres i forhold til den almindelige reguleringsprocent?",
    pay_level:
      "Hvis muligt, angiv lønniveau for den aftalte løn i årligt grundbeløb 31.03.2012",
    pay_scale:
      "Er lønnen aftalt som et skalatrin i forhold til tjenestemændenes lønrammesystem?",
    pay_scale_step: "Skalatrin",
    pay_scale_wage: "Stedtillæg",
    agreed_pay_non_regulated:
      "Angiv den aftalte time eller månedsløn i nutids kr. (skrive felt) eller honorar (A-indkomst) fx aftalt beløb for en gudtjeneste, højmesse m.m.",
    wages_besides_pay: "Skal der ud over time eller månedslønnen ydes tillæg?",
    regulated_wage:
      "Skal tillægget reguleres i forhold til den almindelig reguleringsprocent?",
    regulated_wage_1:
      "Skal tillægget reguleres i forhold til den almindelig reguleringsprocent?",
    regulated_wage_2:
      "Skal tillægget reguleres i forhold til den almindelig reguleringsprocent?",
    agreed_wage: "Angiv det aftalte tillæg i nutids kr.",
    agreed_wage_1: "Angiv det aftalte tillæg i nutids kr.",
    agreed_wage_2: "Angiv det aftalte tillæg i nutids kr.",
    pay_level_wage:
      "Hvis muligt, angiv lønniveau for den aftalte tillæg i årligt grundbeløb 31.03.2012 (felt hvor beløbet angives)",
    pay_level_wage_1:
      "Hvis muligt, angiv lønniveau for den aftalte tillæg i årligt grundbeløb 31.03.2012 (felt hvor beløbet angives)",
    pay_level_wage_2:
      "Hvis muligt, angiv lønniveau for den aftalte tillæg i årligt grundbeløb 31.03.2012 (felt hvor beløbet angives)",
    hired_thirtydays: {
      graver:
        "Er du ansat som graver i en tjenestemandsstilling frem til  30 dage før ansættelsens starttidspunkt?",
      kirketjener:
        "Er du ansat som kirketjener i en tjenestemandsstilling frem til  30 dage før ansættelsens starttidspunkt?",
    },
    official_pension: {
      graver:
        "Modtager du tjenestemandspension? Eller modtager du pension hvortil det offentlige har ydet bidrag (eks. Arbejdsmarkedspension men ikke folkepension)",
      kirketjener:
        "Modtager du tjenestemandspension? Eller modtager du pension hvortil det offentlige har ydet bidrag (eks. Arbejdsmarkedspension men ikke folkepension)",
      gartner:
        "Modtager du pension hvortil det offentlige har ydet bidrag (eks. Arbejdsmarkedspension men ikke folkepension)",
      gartneriarbejder:
        "Modtager du pension hvortil det offentlige har ydet bidrag (eks. Arbejdsmarkedspension men ikke folkepension)",
      hk:
        "Modtager du pension hvortil det offentlige har ydet bidrag (eks. Arbejdsmarkedspension men ikke folkepension)",
    },
    amu: "Har du svendebrev som gartner?",
    employment_location:
      "Ansættelsen sker i hovedstad (H) eller øvrigt land (L)",
    experience_date: "Erfaringsdato",
    monthly_pay_step:
      "Indplacering af månedslønnen sker på basistrin 1 eller 2",
    monthly_pay_step_hk_grp1:
      "Løngruppe 1 - Indplacering af månedslønnen sker som",
    monthly_pay_step_hk_grp2:
      "Løngruppe 2 - Indplacering af månedslønnen sker som",
    monthly_pay_step_hk_grp3:
      "Løngruppe 3 - Indplacering af månedslønnen sker som",
    advancement_date_step2: "Oprykningsdato til trin 2",
    add_wage_supplement_quality: "Er der aftalt kvalifikationstillæg?",
    wage_supplement_quality:
      "Kvalifikationstillæg (angives i årligt grundbeløb 31.3.2012 for en fuldtidsstilling)",
    add_wage_supplement_function: "Er der aftalt funktionstillæg?",
    wage_supplement_function:
      "Funktionstillæg (angives i årligt grundbeløb 31.3.2012 for en fuldtidsstilling)",
    pay:
      "Den af menighedsrådet tilbudte intervalløn ifølge brev sendt til den faglige organisation",
    candidateCprnr: "CPR nr.",
    candidateName: "Lønmodtagers navn",
    candidateAddress: "Adresse",
    candidateZipCode: "Postnr.",
    candidateCity: "By",
    candidateNotes: "Noter",
    add_candidateNotes: "Tilføj noter",
    how_many_years_in_business:
      "Hvor længe har du været ansat i offentlig virksomhed?",
    age25_atleast4years:
      "Er du fyldt 25 år og har du været ansat i mindst 4 år i offentlig virksomhed?",
    age70: "Er du fyldt 70?",
    other_pension: "Pensionsordning",
    years_in_business: "År",
    months_in_business: "Måneder",
    days_in_business: "Dage",
    age20_atleast9months:
      "Er du fyldt 20 år og har du sammenlagt været ansat mindst 9 måneder i offentlig virksomhed?",
    pension_mini_startdate: "Startdato",
    pension_mini_enddate: "Slutdato",
    pension_gruppeliv_startdate: "Startdato",
    pension_gruppeliv_enddate: "Slutdato",
    handlePension:
      "Der kan ikke indbetales til en pensionsordning: Du skal derfor som lønmodtager vælge om dit pensionsbidrag skal:",
    age20_payed_pension:
      "Er du fyldt 20 år og har du i en tidligere ansættelse været omfattet af en arbejdsgiver betalt pensionsordning?",
    date_full_pension: "Overgår til fuld pension den",
    secondary_workplace: "Andre arbejdssteder",
    geographic_obligation: {
      individuel:
        "Er der til stillingen forpligtelse til at udføre tjeneste ved en anden ansættelsesmyndighed?",
      default:
        "Er der til stillingen knyttet en forpligtelse til geografisk fleksibilitet og i givet fald over for hvilke ansættelsesmyndigheder?",
    },
    professional_obligation: {
      individuel:
        "Hvilken stillingtype dækker stillingen? Fx Kirkesanger, kordegn m.m.",
      default:
        "Er der til stillingen knyttet en forpligtelse til faglig fleksibilitet og i givet fald mellem hvilke stillingstyper?",
    },
    authority: "Ansættelsesmyndighed",
    availability_surcharge: "Ydes der rådighedstillæg til stillingen?",
    employee_handbook:
      "Udleveres der en personalehåndbog i forbindelse med ansættelsen?",
    contactName: "Navn",
    contactPhoneNumber: "Telefonnummer",
    contactEmail: "E-mail",
    contactAddress: "Adresse",
    parttimeDays: "Dage",
    parttimeInterval: "Tidsrum",
    parttimeWeekday:
      "Hvilken dag er aftalt som fast fridag for medarbejderen (Angiv ugedag)",
    dayOff:
      "Hvilken dag er aftalt som fast fridag for medarbejderen (Angiv ugedag)",
    daysOff:
      "Hvilke dage er aftalt som faste fridage for medarbejderen (Angiv 1-2 ugedage)",
    trialAgreement: "Prøvetid?",
    workschedule_type: "Arbejdstid eller rammetid?",
    workScheduleHasAdditionalNotes: {
      individuel: "Er der kommentarer til arbejdstid?",
      hk: "Er der kommentarer til arbejdstid?",
      gartner: "Er der kommentarer til arbejdstid?",
      gartneriarbejder: "Er der kommentarer til arbejdstid?",
      graver: "Er der kommentarer til rammetid?",
      kirketjener: "Er der kommentarer til rammetid?",
      workscheduletype0: "Er der kommentarer til arbejdstid?",
      workscheduletype1: "Er der kommentarer til rammetid?",
    },
    workScheduleAdditionalNotes: "Kommentar til skema",
    deals: "Indgåede aftaler? (f.eks. om sort tøj)",
    dealsNotes: "Tilføj aftaler her",
    steps: [
      {
        header: "Arbejdsgiver",
      },
      {
        header: "Ansættelsen",
      },
      {
        header: "Faktuelle oplysninger til beviset",
      },
      {
        header: "Pension",
      },
      {
        header: "Om stillingen",
      },
      {
        header: "Div. aftaler",
      },
    ],
    supportTexts: {
      weekly_hours:
        "Er stillingen under 8 ugentlige timer skal ansættelsen ske på individuelle vilkår",
      pay: ["(Intervalløn) angivet i årligt grundbeløb 31.03.12"],
      availability_surcharge: {
        graver:
          "OBS Graverens rådighedstillæg er gradueret efter opgaver (se overenskomsten)",
      },
      parttime: {
        graver:
          "Deltidsansættelse – oplysninger om den rammetid, der er aftalt med medarbejderen",
        kirketjener:
          "Deltidsansættelse – oplysninger om den rammetid, der er aftalt med medarbejderen",
        default:
          "Deltidsansættelse – oplysninger om den arbejdstid, der er aftalt med medarbejderen",
        workscheduletype0:
          "Deltidsansættelse – oplysninger om den arbejdstid, der er aftalt med medarbejderen",
        workscheduletype1:
          "Deltidsansættelse – oplysninger om den rammetid, der er aftalt med medarbejderen",
      },
      fullTime: {
        graver: "Rammetid",
        kirketjener: "Rammetid",
        default: "Arbejdstid",
        workscheduletype0: "Arbejdstid",
        workscheduletype1: "Rammetid",
      },
      name:
        "Her angives Sogn(es)-, Kirkegårdens -eller provstiudvalgets navn. Det er vigtigt, at det er det korrekte navn på arbejdsgiveren. Ved tvivl anbefales det er finde navnet på Sogn.dk / Provsti.dk",
      cvr:
        "Her angives det CVR nr. som er knyttet til arbejdsgiveren/ansættelsesmyndigheden",
      cpr: "Her angives CPR nr. for lønmodtager, brug formatet 000000-0000",
      individual_hiring:
        "Da du har svaret ja i spørgsmålet ovenfor, skal medarbejderen ansættes på individuelle vilkår. Gå til forsiden og vælg individuel ansættelse.",
      individual_hiring_generel:
        "Medarbejderen skal ansættes på individuelle vilkår. Gå til forsiden og vælg individuel ansættelse.",
      holiday_law:
        "Ansættelsen er omfattet af ferieloven og kan ikke fraviges.",
      experience_date:
        "Hvis du har en erfaringsdato, der er mere end 11 mdr. er du på basistrin 2 og hvis du har mindre end 12 måneder er du på basistrin 1",
    },
  };

  const steps = {
    individuel: {
      names: [
        ["Arbejdsgiver", true, 1],
        ["Ansættelsen", true, 2],
        ["Faktuelle oplysninger", true, 3],
        ["Pension", false, 0],
        ["Om stillingen", false, 0],
        ["Div. aftaler", true, 4],
      ],
      maxSteps: 5,
    },
    gartner: {
      names: [
        ["Arbejdsgiver", true, 1],
        ["Ansættelsen", true, 2],
        ["Faktuelle oplysninger", true, 3],
        ["Pension", true, 4],
        ["Om stillingen", false, 0],
        ["Div. aftaler", true, 5],
      ],
      maxSteps: 5,
    },
    gartneriarbejder: {
      names: [
        ["Arbejdsgiver", true, 1],
        ["Ansættelsen", true, 2],
        ["Faktuelle oplysninger", true, 3],
        ["Pension", true, 4],
        ["Om stillingen", false, 0],
        ["Div. aftaler", true, 5],
      ],
      maxSteps: 5,
    },
    hk: {
      names: [
        ["Arbejdsgiver", true, 1],
        ["Ansættelsen", true, 2],
        ["Faktuelle oplysninger", true, 3],
        ["Pension", true, 4],
        ["Om stillingen", false, 0],
        ["Div. aftaler", true, 5],
      ],
      maxSteps: 5,
    },
    default: {
      names: [
        ["Arbejdsgiver", true, 1],
        ["Ansættelsen", true, 2],
        ["Faktuelle oplysninger", true, 3],
        ["Pension", true, 4],
        ["Om stillingen", true, 5],
        ["Div. aftaler", true, 6],
      ],
      maxSteps: 5,
    },
  };

  const apiData = {
    workplace: [
      {
        label: "",
        value: "",
      },
      {
        label: "Arbejdssted 1",
        value: "arbejdssted1",
      },
      {
        label: "Arbejdssted 2",
        value: "arbejdssted2",
      },
    ],
    employment_location: [
      {
        label: "Hovedstad",
        value: "employmentlocation0",
      },
      {
        label: "Øvrigt land",
        value: "employmentlocation1",
      },
    ],
    remuneration: [
      {
        label: "Timeløn",
        value: "remuneration0",
      },
      {
        label: "Månedsløn",
        value: "remuneration1",
      },
    ],
    regulated_pay: [
      {
        label: "Ja",
        value: "regulatedpay1",
      },
      {
        label: "Nej",
        value: "regulatedpay0",
      },
    ],
    regulated_wage: [
      {
        label: "Ja",
        value: "regulatedwage1",
      },
      {
        label: "Nej",
        value: "regulatedwage0",
      },
    ],
    wages_besides_pay: [
      {
        label: "Ja",
        value: "wagesbesidespay1",
      },
      {
        label: "Nej",
        value: "wagesbesidespay0",
      },
    ],
    pay_scale: [
      {
        label: "Ja",
        value: "payscale1",
      },
      {
        label: "Nej",
        value: "payscale0",
      },
    ],
    monthly_pay_step: [
      {
        label: "Basistrin 1",
        value: "monthlypaystep0",
      },
      {
        label: "Basistrin 2",
        value: "monthlypaystep1",
      },
    ],
    add_wage_supplement_quality: [
      {
        label: "Ja",
        value: "addwagesupplementquality1",
      },
      {
        label: "Nej",
        value: "addwagesupplementquality0",
      },
    ],
    add_wage_supplement_function: [
      {
        label: "Ja",
        value: "addwagesupplementfunction1",
      },
      {
        label: "Nej",
        value: "addwagesupplementfunction0",
      },
    ],
    monthly_pay_step_hk_grp1: [
      {
        label: "",
        value: "",
      },
      {
        label: "kontorfunktionær u/uddannelse , Løngruppe 1, trin 1",
        value: "monthlypaystephk0",
      },
      {
        label: "kontorfunktionær u/uddannelse , Løngruppe 1, trin 2",
        value: "monthlypaystephk1",
        requiredField: "employment_location",
        requiredFieldValue: "employmentlocation0",
      },
    ],
    monthly_pay_step_hk_grp2: [
      {
        label: "",
        value: "",
      },
      {
        label: "kontorfunktionær m/uddannelse , Løngruppe 2, sats 1",
        value: "monthlypaystephk2",
      },
      {
        label: "kontorfunktionær m/uddannelse , Løngruppe 2, sats 2",
        value: "monthlypaystephk3",
      },
    ],
    monthly_pay_step_hk_grp3: [
      {
        label: "",
        value: "",
      },
      {
        label: "Specialister / Ledere , Løngruppe 3, sats 1",
        value: "monthlypaystephk4",
      },
      {
        label: "Specialister / Ledere , Løngruppe 3, sats 2",
        value: "monthlypaystephk5",
      },
      {
        label: "Specialister / Ledere , Løngruppe 3, sats 3",
        value: "monthlypaystephk6",
      },
      {
        label: "Specialister / Ledere , Løngruppe 3, sats 4",
        value: "monthlypaystephk7",
      },
    ],
    secondary_workplace: [
      {
        label: "",
        value: "",
      },
      {
        label: "Arbejdssted 1",
        value: "arbejdssted1",
      },
      {
        label: "Arbejdssted 2",
        value: "arbejdssted2",
      },
    ],
    employment_lasting: [
      {
        label: "Ja",
        value: "empchoices1",
      },
      {
        label: "Nej",
        value: "empchoices0",
      },
    ],
    deals: [
      {
        label: "Ja",
        value: "deals1",
      },
      {
        label: "Nej",
        value: "deals0",
      },
    ],
    add_candidateNotes: [
      {
        label: "Ja",
        value: "addcandidatenotes1",
      },
      {
        label: "Nej",
        value: "addcandidatenotes0",
      },
    ],
    workScheduleHasAdditionalNotes: [
      {
        label: "Ja",
        value: "workScheduleHasAdditionalNotes1",
      },
      {
        label: "Nej",
        value: "workScheduleHasAdditionalNotes0",
      },
    ],
    employment_lasting_reason: [
      {
        label: "Til begivenhed",
        value: "empreasonchoices1",
      },
      {
        label: "Til dato",
        value: "empreasonchoices0",
      },
    ],
    days: [
      {
        label: "Mandag",
        value: 0,
      },
      {
        label: "Tirsdag",
        value: 1,
      },
      {
        label: "Onsdag",
        value: 2,
      },
      {
        label: "Torsdag",
        value: 3,
      },
      {
        label: "Fredag",
        value: 4,
      },
      {
        label: "Lørdag",
        value: 5,
      },
      {
        label: "Søndag",
        value: 6,
      },
    ],
    hired_thirtydays: [
      {
        label: "Ja",
        value: "hiredthirty1",
      },
      {
        label: "Nej",
        value: "hiredthirty0",
      },
    ],
    official_pension: [
      {
        label: "Ja",
        value: "officialpension1",
      },
      {
        label: "Nej",
        value: "officialpension0",
      },
    ],
    amu: [
      {
        label: "Ja, løngruppe 2",
        value: "amu1",
      },
      {
        label: "Nej, løngruppe 1",
        value: "amu0",
      },
    ],
    age70: [
      {
        label: "Ja",
        value: "age70_1",
      },
      {
        label: "Nej",
        value: "age70_0",
      },
    ],
    handlePension: [
      {
        label: "Udbetales som løn",
        value: "handlePension_0",
      },
      {
        label: "Indbetales til en anden pensionsordning",
        value: "handlePension_1",
      },
      {
        label: "Køb af frihed",
        value: "handlePension_2",
      },
    ],
    age25_atleast4years: [
      {
        label: "Ja",
        value: "age25_atleast4years1",
      },
      {
        label: "Nej",
        value: "age25_atleast4years0",
      },
    ],
    age20_atleast9months: [
      {
        label: "Ja",
        value: "age20_atleast9months1",
      },
      {
        label: "Nej",
        value: "age20_atleast9months0",
      },
    ],
    age20_payed_pension: [
      {
        label: "Ja",
        value: "age20_payed_pension1",
      },
      {
        label: "Nej",
        value: "age20_payed_pension0",
      },
    ],
    geographic_obligation: [
      {
        label: "Ja",
        value: "geographic_obligation1",
      },
      {
        label: "Nej",
        value: "geographic_obligation0",
      },
    ],
    professional_obligation: [
      {
        label: "Ja",
        value: "professional_obligation1",
      },
      {
        label: "Nej",
        value: "professional_obligation0",
      },
    ],
    availability_surcharge: [
      {
        label: "Ja",
        value: "availability_surcharge1",
      },
      {
        label: "Nej",
        value: "availability_surcharge0",
      },
    ],
    availability_surcharge_type: [
      {
        label: "1",
        value: "1",
      },
      {
        label: "2",
        value: "2",
      },
      {
        label: "3",
        value: "3",
      },
    ],
    employee_handbook: [
      {
        label: "Ja",
        value: "employee_handbook1",
      },
      {
        label: "Nej",
        value: "employee_handbook0",
      },
    ],
    workschedule_type: [
      {
        label: "Arbejdstid",
        value: "workscheduletype0",
      },
      {
        label: "Rammetid",
        value: "workscheduletype1",
      },
    ],
    trialAgreement: [
      {
        label: "Ja",
        value: "trialAgreement1",
      },
      {
        label: "Nej",
        value: "trialAgreement0",
      },
    ],
  };
  return (
    <ApiDataContext.Provider value={{ apiData, labels, steps }}>
      {children}
    </ApiDataContext.Provider>
  );
}

ApiDataProvider.propTypes = {
  children: PropTypes.object,
};

export default ApiDataProvider;
