import React, { useContext } from "react";
import { FormDataContext } from "context/FormDataContext";
import Step1 from "components/FormStep/step1";
import Step2 from "components/FormStep/step2";
import Step3 from "components/FormStep/step3";
import Step4 from "components/FormStep/step4";
import Step5 from "components/FormStep/step5";
import Step6 from "components/FormStep/step6";

function FormStep() {
  const { currentStep } = useContext(FormDataContext);
  switch (currentStep) {
    case 0:
      return <Step1 headline="Udfyldes af arbejdsgiveren" step={1} />;
    case 1:
      return <Step2 headline="Ansættelsen" step={2} />;
    case 2:
      return <Step3 headline="Faktuelle oplysninger til beviset" step={3} />;
    case 3:
      return <Step4 headline="Pensionsanciennitet" step={4} />;
    case 4:
      return <Step5 headline="Om stillingen" step={5} />;
    case 5:
      return <Step6 headline="Diverse" step={6} />;
    default:
      return <div>Not yet implemented</div>;
  }
}

export default FormStep;
