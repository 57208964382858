import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";

const ListOfUnvalidatedSteps = ({ hideModal }) => {
  const { validatedSteps, updateStep, jobType } = useContext(FormDataContext);
  const { steps } = useContext(ApiDataContext);

  let defaultStepsStatus;
  const names = steps[jobType]?.names ?? steps.default.names;

  const getTitleOfStep = (step) => {
    return names[step - 1][0];
  };

  const onHandleGoToStep = (step) => {
    updateStep(step);
    hideModal();
  };

  const listOfUnvalidatedSteps = [];
  const validatedStepsArray = validatedSteps[jobType]
    ? Object.entries(validatedSteps[jobType])
    : defaultStepsStatus;

  validatedStepsArray.map((step, i) => {
    if (!step[1]) {
      if (names[i][1]) {
        listOfUnvalidatedSteps.push(
          <li key={i}>
            <GoToStep
              href="#"
              onClick={() => onHandleGoToStep(parseInt(step[0] - 1))}
            >
              {getTitleOfStep(step[0])}
            </GoToStep>
          </li>
        );
      }
    }
  });

  if (listOfUnvalidatedSteps.length > 0) {
    return (
      <>
        <p>
          Vær dog opmærksom på at du mangler at udfylde nogle felter under
          følgende:
        </p>
        <ul>{listOfUnvalidatedSteps}</ul>
      </>
    );
  } else {
    return <p>Alle felter er udfylt korrekt</p>;
  }
};

ListOfUnvalidatedSteps.propTypes = {
  hideModal: PropTypes.func,
};

const GoToStep = styled.a`
  color: #000;
`;

export default ListOfUnvalidatedSteps;
