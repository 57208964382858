import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";

const Layout = ({ children, title, jobTitle, showToolBar }) => {
  return (
    <>
      <PageWrapper>
        <Header title={title} jobTitle={jobTitle} showToolBar={showToolBar} />
        <Main>
          <form autoComplete="off">{children}</form>
        </Main>
        <Footer />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
`;

const Main = styled.main`
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  padding: 22px;
  @media (min-width: 1280px) {
    width: 1280px;
  }
  margin: 0 auto;
`;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  jobTitle: PropTypes.string,
  showToolBar: PropTypes.bool,
};

export default Layout;
