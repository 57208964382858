import styled from "styled-components";

const HelpBtn = styled.a`
  border-radius: 100px;
  height: 24px;
  width: 24px;
  background-color: ${(props) => props.theme.color.light_sea_green};
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  margin: 0 0.25em;
  transform: ${(props) => (props.alignBottom ? "translateY(10px)" : "none")};
`;

export default HelpBtn;
