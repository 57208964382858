import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "elements/Label";
import HelpText from "elements/HelpText";
import ErrorMessage from "elements/ErrorMessage";
import { FormDataContext } from "context/FormDataContext";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import da from "date-fns/locale/da";
registerLocale("da", da);

function DateField({ extendedRequired, name, label, subLabel, value, step }) {
  const { updateFormData, errors, validateField } = useContext(FormDataContext);

  const handleOnChange = (date) => {
    if (date == null) {
      updateFormData(step, "", name, extendedRequired);
    } else {
      updateFormData(step, new Date(date), name, extendedRequired);
    }
  };

  const handleOnBlur = (date) => {
    validateField(date);
  };

  const error = errors.filter((err) => err.name === name);

  return (
    <DateFieldWrapper>
      <Label htmlFor={name}>{label}</Label>
      <SubLabel>{subLabel}</SubLabel>
      <StyledDatePicker
        selected={value ? new Date(value) : null}
        onChange={(date) => handleOnChange(date)}
        locale="da"
        dateFormat="dd-MM-yyyy"
        name="{name}"
        isClearable={true}
        onBlur={(date) => handleOnBlur(date)}
      />
      <HelpText>Benyt formatet dd-mm-åååå</HelpText>
      {error.length > 0 && <ErrorMessage>{error[0].msg}</ErrorMessage>}
    </DateFieldWrapper>
  );
}

const DateFieldWrapper = styled.div`
  padding: 0.46875em 0;
`;

const SubLabel = styled.div`
  font-size: 0.75em;
  color: ${(props) => props.theme.color.dark_electric_blue};
`;

const StyledDatePicker = styled(DatePicker)`
  font-size: 12px;
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.color.powder_blue};
  box-sizing: border-box;
  border-radius: 8px;
  height: 32px;
  padding: 0 0.5em;
  min-width: 261px;
  &:focus {
    outline: 0;
  }
`;

DateField.defaultProps = {
  extendedRequired: false,
};

DateField.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string,
  isVisible: PropTypes.bool,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  extendedRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      requiredField: PropTypes.string,
      requiredValue: PropTypes.string,
    }),
  ]),
  value: PropTypes.instanceOf(Date),
};

export default DateField;
