import React from "react";
import PropTypes from "prop-types";
import FormDataProvider from "context/FormDataContext";
import ApiDataProvider from "context/ApiDataContext";
import { ThemeProvider } from "styled-components";

function Provider({ children }) {
  const theme = {
    color: {
      powder_blue: "#ACD7D5",
      dark_electric_blue: "#5A6B7E",
      light_sea_green: "#3EB8AD",
      straw: "#EBE26E",
      middle_blue: "#68C3D4",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <ApiDataProvider>
        <FormDataProvider>{children}</FormDataProvider>
      </ApiDataProvider>
    </ThemeProvider>
  );
}

Provider.propTypes = {
  children: PropTypes.object,
};

export default Provider;
