import styled from "styled-components";

const Input = styled.input`
  font-size: 12px;
  font-weight: 500;
  border: 1px solid ${(props) => props.theme.color.powder_blue};
  box-sizing: border-box;
  border-radius: 8px;
  height: 32px;
  padding: 0 0.5em;
  min-width: 261px;
  &:focus {
    outline: 0;
  }
`;

export default Input;
