import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Headline from "elements/Headline";
import RadioButtonsField from "components/form/RadioButtonsField";
import HelpText from "elements/HelpText";
import InputField from "components/form/InputField";
import IndentedSection from "elements/IndentedSection";
import DynamicInputField from "components/form/DynamicInputField";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";
import { validateStep } from "utils/validateStep";
import handleFields from "utils/handleFields";
import Label from "elements/Label";

function Step5({ headline, step }) {
  const {
    formData,
    jobType,
    validatedSteps,
    setValidatedSteps,
    updateDynamicFieldsList,
    dynamicFieldsList,
  } = useContext(FormDataContext);
  const { apiData, labels } = useContext(ApiDataContext);

  const fieldsWithRequiredValidation = jobType === "individuel" ? 1 : 2;
  const fields = handleFields(
    fieldsWithRequiredValidation,
    step,
    formData[jobType],
    jobType
  );

  useEffect(() => {
    validateStep(
      fields,
      step,
      formData,
      validatedSteps,
      setValidatedSteps,
      jobType
    );
  }, [formData]);

  useEffect(() => {
    // Start with one field by default
    const isChecked =
      formData[jobType]?.geographic_obligation?.value ===
      "geographic_obligation1";
    const isEmpty = dynamicFieldsList[jobType].secondary_workplace === 0;

    if (isChecked && isEmpty) {
      updateDynamicFieldsList("secondary_workplace", 1);
    }
  }, [formData[jobType]?.geographic_obligation?.value]);

  return (
    <>
      <Headline>{headline}</Headline>
      <RadioButtonsField
        step={step}
        name="geographic_obligation"
        label={
          labels.geographic_obligation[jobType]
            ? labels.geographic_obligation[jobType]
            : labels.geographic_obligation.default
        }
        options={apiData.geographic_obligation}
        value={formData[jobType]?.geographic_obligation?.value}
      />

      {formData[jobType]?.geographic_obligation?.value ===
        "geographic_obligation1" && (
        <IndentedSection>
          <Label>{labels.secondary_workplace}</Label>
          <DynamicInputField
            fields={fields}
            step={step}
            name="secondary_workplace"
            label={labels.secondary_workplace}
            value={formData[jobType]?.secondary_workplace?.value}
          />
        </IndentedSection>
      )}
      <InputField
        step={step}
        name="professional_obligation"
        label={
          labels.professional_obligation[jobType]
            ? labels.professional_obligation[jobType]
            : labels.professional_obligation.default
        }
        value={formData[jobType]?.professional_obligation?.value}
        validationRequired={
          formData[jobType]?.professional_obligation?.validationRequired
        }
      />
      {jobType !== "individuel" && (
        <>
          <RadioButtonsField
            step={step}
            name="availability_surcharge"
            label={labels.availability_surcharge}
            options={apiData.availability_surcharge}
            value={formData[jobType]?.availability_surcharge?.value}
          />
          {formData[jobType]?.availability_surcharge?.value ===
            "availability_surcharge1" &&
            jobType !== "kirketjener" && (
              <IndentedSection>
                <RadioButtonsField
                  extendedRequired={{
                    requiredField: "availability_surcharge",
                    requiredValue: "availability_surcharge1",
                  }}
                  step={step}
                  name="availability_surcharge_type"
                  label={labels.availability_surcharge_type}
                  options={apiData.availability_surcharge_type}
                  value={formData[jobType]?.availability_surcharge_type?.value}
                />
              </IndentedSection>
            )}
          <HelpText>
            {labels.supportTexts.availability_surcharge[jobType] ?? ""}
          </HelpText>
        </>
      )}
    </>
  );
}

Step5.propTypes = {
  step: PropTypes.number,
  headline: PropTypes.string,
};

export default Step5;
