import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Headline from "elements/Headline";
import Label from "elements/Label";
import InputField from "components/form/InputField";
import RadioButtonsField from "components/form/RadioButtonsField";
import TextAreaField from "components/form/TextAreaField";
import IndentedSection from "elements/IndentedSection";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";
import { validateStep } from "utils/validateStep";
import handleFields from "utils/handleFields";
import DayOffField from "components/form/DayOffField";
import DaysOffField from "components/form/DaysOffField";
import WorkScheduleField from "components/form/WorkScheduleField";

function Step6({ headline, step }) {
  const { formData, jobType, validatedSteps, setValidatedSteps } = useContext(
    FormDataContext
  );

  const { apiData, labels } = useContext(ApiDataContext);

  // WorkSchedule is valid by default, therefore only 7 fields
  const fieldsWithRequiredValidation = 7;
  const fields = handleFields(
    fieldsWithRequiredValidation,
    step,
    formData[jobType]
  );

  useEffect(() => {
    validateStep(
      fields,
      step,
      formData,
      validatedSteps,
      setValidatedSteps,
      jobType
    );
  }, [formData]);

  return (
    <>
      <Headline>{headline}</Headline>
      <RadioButtonsField
        step={step}
        name="employee_handbook"
        label={labels.employee_handbook}
        options={apiData.employee_handbook}
        value={formData[jobType]?.employee_handbook?.value}
      />
      <Label legend margintop>
        Aktuel kontaktperson
      </Label>
      <InputField
        step={step}
        name="contactName"
        label={labels.contactName}
        value={formData[jobType]?.contactName?.value}
      />
      <InputField
        step={step}
        name="contactAddress"
        label={labels.contactAddress}
        value={formData[jobType]?.contactAddress?.value}
      />
      <InputField
        step={step}
        name="contactPhoneNumber"
        label={labels.contactPhoneNumber}
        value={formData[jobType]?.contactPhoneNumber?.value}
      />
      <InputField
        step={step}
        name="contactEmail"
        label={labels.contactEmail}
        value={formData[jobType]?.contactEmail?.value}
        validationRequired={formData[jobType]?.contactEmail?.validationRequired}
      />
      <Label margintop legend>
        Fridag(e)
      </Label>
      {jobType === "gartner" || jobType === "gartneriarbejder" ? (
        <DaysOffField
          step={step}
          name="workSchedule"
          label={labels.daysOff}
          options={apiData.days}
          value={formData[jobType]?.workSchedule?.value}
        />
      ) : (
        <DayOffField
          step={step}
          name="workSchedule"
          label={labels.dayOff}
          options={apiData.days}
          value={formData[jobType]?.workSchedule?.value}
        />
      )}
      {jobType === "individuel" && (
        <RadioButtonsField
          step={step}
          name="workschedule_type"
          label={labels.workschedule_type}
          options={apiData.workschedule_type}
          value={formData[jobType]?.workschedule_type?.value}
        />
      )}
      {formData[jobType]?.workschedule_type?.value !== "" ? (
        <Label margintop legend>
          {formData[jobType]?.weekly_hours?.value >= 37
            ? labels.supportTexts.fullTime[
                formData[jobType]?.workschedule_type?.value
              ] ?? labels.supportTexts.fullTime.default
            : labels.supportTexts.parttime[
                formData[jobType]?.workschedule_type?.value
              ] ?? labels.supportTexts.parttime.default}
        </Label>
      ) : (
        <Label margintop legend>
          {formData[jobType]?.weekly_hours?.value >= 37
            ? labels.supportTexts.fullTime[jobType] ??
              labels.supportTexts.fullTime.default
            : labels.supportTexts.parttime[jobType] ??
              labels.supportTexts.parttime.default}
        </Label>
      )}
      <WorkScheduleField
        step={step}
        name="workSchedule"
        label={
          labels.supportTexts.parttime[jobType] ??
          labels.supportTexts.parttime.default
        }
        options={apiData.days}
        value={formData[jobType]?.workSchedule?.value}
      />
      {formData[jobType]?.workschedule_type?.value !== "" ? (
        <RadioButtonsField
          step={step}
          name="workScheduleHasAdditionalNotes"
          label={
            labels.workScheduleHasAdditionalNotes[
              formData[jobType]?.workschedule_type?.value
            ]
          }
          options={apiData.workScheduleHasAdditionalNotes}
          value={formData[jobType]?.workScheduleHasAdditionalNotes?.value}
        />
      ) : (
        <RadioButtonsField
          step={step}
          name="workScheduleHasAdditionalNotes"
          label={labels.workScheduleHasAdditionalNotes[jobType]}
          options={apiData.workScheduleHasAdditionalNotes}
          value={formData[jobType]?.workScheduleHasAdditionalNotes?.value}
        />
      )}
      {formData[jobType]?.workScheduleHasAdditionalNotes?.value ===
        "workScheduleHasAdditionalNotes1" && (
        <IndentedSection>
          <TextAreaField
            step={step}
            name="workScheduleAdditionalNotes"
            extendedRequired={{
              requiredField: "workScheduleHasAdditionalNotes",
              requiredValue: "workScheduleHasAdditionalNotes1",
            }}
            label={labels.workScheduleAdditionalNotes}
            value={formData[jobType]?.workScheduleAdditionalNotes?.value}
          />
        </IndentedSection>
      )}
      <RadioButtonsField
        step={step}
        name="deals"
        label={labels.deals}
        options={apiData.deals}
        value={formData[jobType]?.deals?.value}
      />
      {formData[jobType]?.deals?.value === "deals1" && (
        <IndentedSection>
          <TextAreaField
            step={step}
            name="dealsNotes"
            extendedRequired={{
              requiredField: "deals",
              requiredValue: "deals1",
            }}
            label={labels.dealsNotes}
            value={formData[jobType]?.dealsNotes?.value}
          />
        </IndentedSection>
      )}
    </>
  );
}

Step6.propTypes = {
  step: PropTypes.number,
  headline: PropTypes.string,
};

export default Step6;
