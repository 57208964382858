import React, { useContext, useEffect } from "react";
import Layout from "components/Layout";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { FormDataContext } from "context/FormDataContext";

const LargeButton = styled.div`
  a {
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    margin: 31px 44px;
    width: 255px;
    height: 137px;
    border-radius: 10px;
    display: block;
    border: 1px solid #000;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #000;
    transition: 0.5s background;

    &:hover {
      color: #fff;
      background: #000;
    }
  }
`;

const Flexbox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function JobTitleSelector() {
  const { setJobType, updateStep } = useContext(FormDataContext);

  useEffect(() => {
    updateStep(0);
  });

  return (
    <Layout>
      <Flexbox>
        <LargeButton onClick={() => setJobType("graver")}>
          <Link to="/apron/graver">Graver &gt;</Link>
        </LargeButton>
        <LargeButton onClick={() => setJobType("gartner")}>
          <Link to="/apron/gartner">Gartner &gt;</Link>
        </LargeButton>
        <LargeButton onClick={() => setJobType("gartneriarbejder")}>
          <Link to="/apron/gartneriarbejder">Gartneriarbejder &gt;</Link>
        </LargeButton>
        <LargeButton onClick={() => setJobType("kirketjener")}>
          <Link to="/apron/kirketjener">Kirketjener &gt;</Link>
        </LargeButton>
        <LargeButton onClick={() => setJobType("hk")}>
          <Link to="/apron/HK">HK &gt;</Link>
        </LargeButton>
        <LargeButton onClick={() => setJobType("individuel")}>
          <Link to="/apron/individuel">Individuel Ansættelse &gt;</Link>
        </LargeButton>
      </Flexbox>
    </Layout>
  );
}

export default JobTitleSelector;
