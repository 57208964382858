import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "elements/Button";
import { FormDataContext } from "context/FormDataContext";

function WorkScheduleField({
  extendedRequired,
  name,
  value,
  step,
  options,
  printversion,
}) {
  const { updateFormData } = useContext(FormDataContext);
  const [weeklyHours, setWeeklyHours] = useState(0);

  const handleNoTime = (weekday, notime) => {
    let workSchedule = value.map((day, i) => {
      if (weekday === i) {
        return { ...day, notime: !notime };
      } else {
        return { ...day };
      }
    });
    updateFormData(step, workSchedule, name, extendedRequired);
  };

  const handleSelectTime = (e, weekday, type) => {
    let workSchedule = value;
    const time = e.target.value;
    workSchedule[weekday][type] = time;
    const hoursTotalDay =
      workSchedule[weekday].end - workSchedule[weekday].start;
    workSchedule[weekday].hoursTotal = hoursTotalDay > 0 ? hoursTotalDay : 0;
    updateFormData(step, workSchedule, name, extendedRequired);
  };

  useEffect(() => {
    let calculatedWeeklyHours = 0;
    value.map((day) => {
      if (day.notime === false && day.dayoff === false) {
        calculatedWeeklyHours = calculatedWeeklyHours + day.hoursTotal;
      }
    });
    setWeeklyHours(calculatedWeeklyHours);
  });

  const days = [];

  const convertToTimeFormat = (time) => {
    if (time.indexOf(".") > 0) {
      return time.replace(".5", ":30");
    } else if (time) {
      return time + ":00";
    } else {
      return "0:00";
    }
  };

  const showSelect = (currentTime, dayoff, notime, weekday, type) => {
    if (dayoff || notime) {
      return <div></div>;
    } else {
      return (
        <select
          onChange={(e) => handleSelectTime(e, weekday, type)}
          value={currentTime}
        >
          <option value=""></option>
          <option value="6">6:00</option>
          <option value="6.5">6:30</option>
          <option value="7">7:00</option>
          <option value="7.5">7:30</option>
          <option value="8">8:00</option>
          <option value="8.5">8:30</option>
          <option value="9">9:00</option>
          <option value="9.5">9:30</option>
          <option value="10">10:00</option>
          <option value="10.5">10:30</option>
          <option value="11">11:00</option>
          <option value="11.5">11:30</option>
          <option value="12">12:00</option>
          <option value="12.5">12:30</option>
          <option value="13">13:00</option>
          <option value="13.5">13:30</option>
          <option value="14">14:00</option>
          <option value="14.5">14:30</option>
          <option value="15">15:00</option>
          <option value="15.5">15:30</option>
          <option value="16">16:00</option>
          <option value="16.5">16:30</option>
          <option value="17">17:00</option>
          <option value="17.5">17:30</option>
          <option value="18">18:00</option>
          <option value="18.5">18:30</option>
          <option value="19">19:00</option>
          <option value="19.5">19:30</option>
          <option value="20">20:00</option>
          <option value="20.5">20:30</option>
          <option value="21">21:00</option>
          <option value="21.5">21:30</option>
          <option value="22">22:00</option>
          <option value="22.5">22:30</option>
          <option value="23">23:00</option>
        </select>
      );
    }
  };

  for (let i = 0; i < value.length; i++) {
    days.push(
      <Day dayoff={value[i].dayoff} notime={value[i].notime} key={i}>
        <div>
          <Title>{options[i].label}</Title>
          {value[i].dayoff && <StatusText>Fridag</StatusText>}
          {value[i].notime && (
            <StatusText>Intet fast start- og sluttidspunkt</StatusText>
          )}
          <StartTime>
            {printversion && !value[i].dayoff && !value[i].notime
              ? convertToTimeFormat(value[i].start)
              : showSelect(
                  value[i].start,
                  value[i].dayoff,
                  value[i].notime,
                  i,
                  "start"
                )}
          </StartTime>
          <EndTime>
            {printversion && !value[i].dayoff && !value[i].notime
              ? convertToTimeFormat(value[i].end)
              : showSelect(
                  value[i].end,
                  value[i].dayoff,
                  value[i].notime,
                  i,
                  "end"
                )}
          </EndTime>
        </div>
        <Columns>
          {!value[i].dayoff && !value[i].notime && (
            <HoursTotalValue>{value[i].hoursTotal}</HoursTotalValue>
          )}
          {!value[i].dayoff && !printversion && (
            <MyButton
              type="button"
              dayOff={value[i].dayoff || value[i].notime}
              onClick={() => handleNoTime(i, value[i].notime)}
            >
              {value[i].notime ? "Angiv" : "Ingen"}
            </MyButton>
          )}
        </Columns>
      </Day>
    );
  }

  return (
    <>
      <Schema>
        <Day>
          <Title>Dag</Title>
          <StartTime>Start</StartTime>
          <EndTime>Slut</EndTime>
          <HoursTotal>Antal timer ({weeklyHours})</HoursTotal>
        </Day>
        {days}
      </Schema>
    </>
  );
}

const Day = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0.25em;
  border: 1px solid #3eb8ad;
  ${(props) => {
    if (props.dayoff) {
      return `
        background-color: #1D9C08;
        color: #ffffff;
      `;
    } else if (props.notime) {
      return `
        background-color: #eee;
        color: #000000;
      `;
    } else {
      `
        background-color: #ffffff;
        color: ${(props) => props.theme.color.dark_electric_blue};
      `;
    }
  }};
  padding: 0.5em;
`;

const MyButton = styled(Button)`
  &:last-child {
    margin: 0;
  }
  align-self: flex-end;
  background-color: ${(props) => props.theme.color.dark_electric_blue};
  color: #fff;
  &:hover {
    background-color: ${(props) => props.theme.color.dark_electric_blue};
  }
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StatusText = styled.p`
  margin: 0;
  font-size: 13px;
  max-width: 90px;
`;
const Schema = styled.div`
  display: flex;
  margin: 1em 0;
`;
const StartTime = styled.div`
  width: 90px;
  margin: 1em 0;
  font-size: 0.85em;
`;
const EndTime = styled.div`
  width: 90px;
  font-size: 0.85em;
`;
const HoursTotal = styled.div`
  width: 70px;
  margin: 1.95em 0 0 0;
  width: 125px;
  font-size: 0.85em;
`;
const HoursTotalValue = styled.div`
  font-size: 0.85em;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 0.85em;
`;

Option.propTypes = {
  value: PropTypes.array,
  label: PropTypes.string,
};

WorkScheduleField.defaultProps = {
  extendedRequired: false,
};

WorkScheduleField.propTypes = {
  step: PropTypes.number,
  printversion: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.array,
  options: PropTypes.array,
  extendedRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      requiredField: PropTypes.string,
      requiredValue: PropTypes.string,
    }),
  ]),
};

export default WorkScheduleField;
