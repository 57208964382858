export const defaultFormData = {
  cvr: {
    value: "",
  },
  name: {
    value: "",
  },
  address: {
    value: "",
  },
  city: {
    value: "",
  },
  workplace: {
    value: "",
  },
  starttime_of_employment: {
    value: "",
  },
  weekly_hours: {
    value: "",
  },
  employment_lasting_reason: {
    value: "",
  },
  employment_location: {
    value: "",
  },
  reason_for_nonlastingemployment: {
    value: "",
  },
  limited_to_event: {
    value: "",
  },
  limited_to_date: {
    value: "",
  },
  pay: {
    value: "",
  },
  candidateCprnr: {
    value: "",
  },
  candidateName: {
    value: "",
  },
  candidateAddress: {
    value: "",
  },
  candidateCity: {
    value: "",
  },
  candidateNotes: {
    value: "",
  },
  secondary_workplace: {
    value: [],
    fields: ["Adresse", "Postnr", "By", "Ansættelsesmyndighed"],
  },
  wage_supplement_quality: {
    value: [],
    fields: ["Navn", "Beløb"],
  },
  wage_supplement_function: {
    value: [],
    fields: ["", ""],
  },
  authority: {
    value: "",
  },
  contactName: {
    value: "",
  },
  contactPhoneNumber: {
    value: "",
  },
  contactEmail: {
    value: "",
    validationRequired: false,
  },
  contactAddress: {
    value: "",
  },
  days_in_business_startdate: {
    value: "",
  },
  professional_obligation: {
    value: "",
    validationRequired: false,
  },
  pay_level: {
    value: "",
    validationRequired: false,
  },
  pay_level_wage_1: {
    value: "",
    validationRequired: false,
  },
  pay_level_wage_2: {
    value: "",
    validationRequired: false,
  },
  parttimeDays: {
    value: "",
  },
  dayOff: {
    value: null,
  },
  workschedule_type: {
    value: "",
  },
  workSchedule: {
    value: [
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: true, notime: false, start: "", end: "", hoursTotal: 0 },
    ],
    validationRequired: false,
  },
  parttimeInterval: {
    value: "",
  },
  trialAgreement: {
    value: "",
  },
};

export const defaultFormDataGartner = {
  cvr: {
    value: "",
  },
  name: {
    value: "",
  },
  address: {
    value: "",
  },
  city: {
    value: "",
  },
  workplace: {
    value: "",
  },
  starttime_of_employment: {
    value: "",
  },
  weekly_hours: {
    value: "",
  },
  employment_lasting_reason: {
    value: "",
  },
  employment_location: {
    value: "",
  },
  reason_for_nonlastingemployment: {
    value: "",
  },
  limited_to_event: {
    value: "",
  },
  limited_to_date: {
    value: "",
  },
  pay: {
    value: "",
  },
  candidateCprnr: {
    value: "",
  },
  candidateName: {
    value: "",
  },
  candidateAddress: {
    value: "",
  },
  candidateCity: {
    value: "",
  },
  candidateNotes: {
    value: "",
  },
  secondary_workplace: {
    value: [],
    fields: ["Adresse", "Postnr", "By", "Ansættelsesmyndighed"],
  },
  wage_supplement_quality: {
    value: [],
    fields: ["Navn", "Beløb"],
  },
  wage_supplement_function: {
    value: [],
    fields: ["", ""],
  },
  authority: {
    value: "",
  },
  contactName: {
    value: "",
  },
  contactPhoneNumber: {
    value: "",
  },
  contactEmail: {
    value: "",
    validationRequired: false,
  },
  contactAddress: {
    value: "",
  },
  days_in_business_startdate: {
    value: "",
  },
  professional_obligation: {
    value: "",
    validationRequired: false,
  },
  pay_level: {
    value: "",
    validationRequired: false,
  },
  pay_level_wage_1: {
    value: "",
    validationRequired: false,
  },
  pay_level_wage_2: {
    value: "",
    validationRequired: false,
  },
  parttimeDays: {
    value: "",
  },
  dayOff: {
    value: null,
  },
  workschedule_type: {
    value: "",
  },
  workSchedule: {
    value: [
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: true, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: true, notime: false, start: "", end: "", hoursTotal: 0 },
    ],
    validationRequired: false,
  },
  parttimeInterval: {
    value: "",
  },
  trialAgreement: {
    value: "",
  },
};

export const defaultFormDataIndividuel = {
  cvr: {
    value: "",
  },
  name: {
    value: "",
  },
  address: {
    value: "",
  },
  city: {
    value: "",
  },
  workplace: {
    value: "",
  },
  starttime_of_employment: {
    value: "",
  },
  weekly_hours: {
    value: "",
  },
  employment_lasting_reason: {
    value: "",
  },
  employment_location: {
    value: "",
  },
  reason_for_nonlastingemployment: {
    value: "",
  },
  limited_to_event: {
    value: "",
  },
  limited_to_date: {
    value: "",
  },
  pay: {
    value: "",
  },
  candidateCprnr: {
    value: "",
  },
  candidateName: {
    value: "",
  },
  candidateAddress: {
    value: "",
  },
  candidateCity: {
    value: "",
  },
  candidateNotes: {
    value: "",
  },
  secondary_workplace: {
    value: [],
    fields: ["Adresse", "Postnr", "By", "Ansættelsesmyndighed"],
  },
  wage_supplement_quality: {
    value: [],
    fields: ["Navn", "Beløb"],
  },
  wage_supplement_function: {
    value: [],
    fields: ["", ""],
  },
  authority: {
    value: "",
  },
  contactName: {
    value: "",
  },
  contactPhoneNumber: {
    value: "",
  },
  contactEmail: {
    value: "",
    validationRequired: false,
  },
  contactAddress: {
    value: "",
  },
  days_in_business_startdate: {
    value: "",
  },
  professional_obligation: {
    value: "",
    validationRequired: false,
  },
  pay_level: {
    value: "",
    validationRequired: false,
  },
  pay_level_wage_1: {
    value: "",
    validationRequired: false,
  },
  pay_level_wage_2: {
    value: "",
    validationRequired: false,
  },
  parttimeDays: {
    value: "",
  },
  dayOff: {
    value: null,
  },
  workschedule_type: {
    value: "workscheduletype0",
  },
  workSchedule: {
    value: [
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: false, notime: false, start: "", end: "", hoursTotal: 0 },
      { dayoff: true, notime: false, start: "", end: "", hoursTotal: 0 },
    ],
    validationRequired: false,
  },
  parttimeInterval: {
    value: "",
  },
  trialAgreement: {
    value: "",
  },
};
