import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Label from "elements/Label";
import ErrorMessage from "elements/ErrorMessage";
import Select from "elements/Select";
import { FormDataContext } from "context/FormDataContext";

const SelectFieldWrapper = styled.div`
  padding: 0.46875em 0;
`;

function Option({ label, value }) {
  return <option value={value}>{label}</option>;
}

function SelectField({ extendedRequired, name, label, value, options, step }) {
  const {
    formData,
    updateFormData,
    errors,
    validateField,
    jobType,
  } = useContext(FormDataContext);

  const myOptions = options.map((d, i) => {
    if (
      !d.requiredField ||
      formData[jobType]?.[d.requiredField]?.value === d.requiredFieldValue
    ) {
      return <Option value={d.value} key={i} label={d.label} />;
    }
  });

  const handleOnChange = (e) => {
    updateFormData(step, e, name, extendedRequired);
    validateField(e);
  };

  const error = errors.filter((err) => err.name === name);

  return (
    <SelectFieldWrapper>
      <Label htmlFor={name}>{label}</Label>
      <SelectBox
        id={name}
        name={name}
        onChange={(e) => handleOnChange(e)}
        value={value}
      >
        {myOptions}
      </SelectBox>
      {error.length > 0 && <ErrorMessage>{error[0].msg}</ErrorMessage>}
    </SelectFieldWrapper>
  );
}

const SelectBox = styled(Select)`
  padding-right: 2.5em;
`;

Option.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
};

SelectField.defaultProps = {
  extendedRequired: false,
};

SelectField.propTypes = {
  step: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  extendedRequired: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      requiredField: PropTypes.string,
      requiredValue: PropTypes.string,
    }),
  ]),
};

export default SelectField;
