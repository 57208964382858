import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import JobTitleSelector from "pages/JobTitleSelector";
import Apron from "pages/Apron";
import PrintPreview from "pages/PrintPreview";

function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/apron/:jobTitle">
          <Apron />
        </Route>
        <Route path="/print/">
          <PrintPreview />
        </Route>
        <Route path="/">
          <JobTitleSelector />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
