import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import StyledDatePicker from "components/form/DateField";
import styled from "styled-components";
import Headline from "elements/Headline";
import { useHistory } from "react-router-dom";
import IndentedSection from "elements/IndentedSection";
import HelpText from "elements/HelpText";
import InputField from "components/form/InputField";
import Label from "elements/Label";
import RadioButtonsField from "components/form/RadioButtonsField";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";
import { validateStep } from "utils/validateStep";
import handleFields from "utils/handleFields";

function Step4({ headline, step }) {
  const { formData, jobType, validatedSteps, setValidatedSteps } = useContext(
    FormDataContext
  );

  let history = useHistory();

  const redirectToPage = (location) => {
    history.push(location);
  };

  const { apiData, labels } = useContext(ApiDataContext);

  const defaultFields = 1;
  const fields = handleFields(defaultFields, step, formData[jobType]);

  useEffect(() => {
    validateStep(
      fields,
      step,
      formData,
      validatedSteps,
      setValidatedSteps,
      jobType
    );
  }, [formData]);

  if (formData[jobType]?.official_pension?.value === "officialpension1") {
    return (
      <Label>
        {labels.supportTexts.individual_hiring_generel}
        <LinkToFrontPage href="" onClick={() => redirectToPage("/")}>
          Gå til forsiden
        </LinkToFrontPage>
      </Label>
    );
  } else {
    return (
      <>
        <Headline>{headline}</Headline>
        <RadioButtonsField
          step={step}
          name="age70"
          label={labels.age70}
          options={apiData.age70}
          value={formData[jobType]?.age70?.value}
        />
        {formData[jobType]?.age70?.value === "age70_1" && (
          <IndentedSection>
            <RadioButtonsField
              extendedRequired={{
                requiredField: "age70",
                requiredValue: "age70_1",
              }}
              step={step}
              name="handlePension"
              label={labels.handlePension}
              options={apiData.handlePension}
              value={formData[jobType]?.handlePension?.value}
            />
            {formData[jobType]?.handlePension?.value === "handlePension_1" && (
              <IndentedSection>
                <InputField
                  extendedRequired={{
                    requiredField: "handlePension",
                    requiredValue: "handlePension_1",
                  }}
                  step={step}
                  name="other_pension"
                  label={labels.other_pension}
                  value={formData[jobType]?.other_pension?.value}
                />
              </IndentedSection>
            )}
          </IndentedSection>
        )}
        {formData[jobType]?.age70?.value === "age70_0" && (
          <IndentedSection>
            <RadioButtonsField
              extendedRequired={{
                requiredField: "age70",
                requiredValue: "age70_0",
              }}
              step={step}
              name="age25_atleast4years"
              label={labels.age25_atleast4years}
              options={apiData.age25_atleast4years}
              value={formData[jobType]?.age25_atleast4years?.value}
            />
            {formData[jobType]?.age25_atleast4years?.value ===
              "age25_atleast4years1" &&
              formData[jobType]?.age70?.value === "age70_0" && (
                <IndentedSection>
                  <Label legend>Fuld pension</Label>
                  <Label>
                    Hvor længe har du været ansat i offentlig virksomhed?
                  </Label>
                  <InputField
                    extendedRequired={{
                      requiredField: "age25_atleast4years",
                      requiredValue: "age25_atleast4years1",
                    }}
                    step={step}
                    name="years_in_business"
                    label={labels.years_in_business}
                    value={formData[jobType]?.years_in_business?.value}
                  />
                  <InputField
                    extendedRequired={{
                      requiredField: "age25_atleast4years",
                      requiredValue: "age25_atleast4years1",
                    }}
                    step={step}
                    name="months_in_business"
                    label={labels.months_in_business}
                    value={formData[jobType]?.months_in_business?.value}
                  />
                </IndentedSection>
              )}
            {formData[jobType]?.age25_atleast4years?.value ===
              "age25_atleast4years0" && (
              <IndentedSection>
                <RadioButtonsField
                  step={step}
                  extendedRequired={{
                    requiredField: "age25_atleast4years",
                    requiredValue: "age25_atleast4years0",
                  }}
                  name="age20_atleast9months"
                  label={labels.age20_atleast9months}
                  options={apiData.age20_atleast9months}
                  value={formData[jobType]?.age20_atleast9months?.value}
                />
                {formData[jobType]?.age20_atleast9months?.value ===
                  "age20_atleast9months0" && (
                  <IndentedSection>
                    <RadioButtonsField
                      step={step}
                      extendedRequired={{
                        requiredField: "age20_atleast9months",
                        requiredValue: "age20_atleast9months0",
                      }}
                      name="age20_payed_pension"
                      label={labels.age20_payed_pension}
                      options={apiData.age20_payed_pension}
                      value={formData[jobType]?.age20_payed_pension?.value}
                    />
                  </IndentedSection>
                )}
              </IndentedSection>
            )}
          </IndentedSection>
        )}
        {/* GRUPPELIV */}
        {formData[jobType]?.age20_payed_pension?.value ===
          "age20_payed_pension0" &&
          formData[jobType]?.age20_atleast9months?.value ===
            "age20_atleast9months0" &&
          formData[jobType]?.age25_atleast4years?.value ===
            "age25_atleast4years0" &&
          formData[jobType]?.age70?.value === "age70_0" && (
            <>
              <Label legend margintop>
                Gruppeliv
              </Label>
              <StartAndEndDate>
                <StyledDatePicker
                  extendedRequired={{
                    requiredField: "age20_atleast9months",
                    requiredValue: "age20_atleast9months0",
                  }}
                  step={step}
                  name="pension_gruppeliv_startdate"
                  label={labels.pension_gruppeliv_startdate}
                  value={formData[jobType]?.pension_gruppeliv_startdate?.value}
                />
                <StyledDatePicker
                  extendedRequired={{
                    requiredField: "age20_atleast9months",
                    requiredValue: "age20_atleast9months0",
                  }}
                  step={step}
                  name="pension_gruppeliv_enddate"
                  label={labels.pension_gruppeliv_enddate}
                  value={formData[jobType]?.pension_gruppeliv_enddate?.value}
                />
              </StartAndEndDate>
            </>
          )}
        {/* MINIPENSION */}
        {(formData[jobType]?.age20_payed_pension?.value ===
          "age20_payed_pension1" ||
          formData[jobType]?.age20_atleast9months?.value ===
            "age20_atleast9months1" ||
          formData[jobType]?.age20_payed_pension?.value ===
            "age20_payed_pension0") &&
          formData[jobType]?.age70?.value === "age70_0" &&
          formData[jobType]?.age25_atleast4years?.value ===
            "age25_atleast4years0" && (
            <>
              <Label legend margintop>
                Minipension
              </Label>
              <StartAndEndDate>
                <StyledDatePicker
                  extendedRequired={{
                    requiredField: "age20_atleast9months",
                    requiredValue: "age20_atleast9months0",
                  }}
                  step={step}
                  name="pension_mini_startdate"
                  label={labels.pension_mini_startdate}
                  value={formData[jobType]?.pension_mini_startdate?.value}
                />
                <StyledDatePicker
                  extendedRequired={{
                    requiredField: "age20_atleast9months",
                    requiredValue: "age20_atleast9months0",
                  }}
                  step={step}
                  name="pension_mini_enddate"
                  label={labels.pension_mini_enddate}
                  value={formData[jobType]?.pension_mini_enddate?.value}
                />
              </StartAndEndDate>
              <StyledDatePicker
                extendedRequired={{
                  requiredField: "age20_atleast9months",
                  requiredValue: "age20_atleast9months0",
                }}
                step={step}
                name="date_full_pension"
                label={labels.date_full_pension}
                value={formData[jobType]?.date_full_pension?.value}
              />
            </>
          )}
        <HelpText wide>
          Sker ansættelsen i fleksjob eller lignende ordninger skal Stifternes
          løncenter kontaktes før indberetningen til lønsystemet sker. Årsagen
          hertil er afklaring af gruppelivsordning og evt. anden
          pensionsselskab.{" "}
        </HelpText>
      </>
    );
  }
}

Step4.propTypes = {
  headline: PropTypes.string,
  step: PropTypes.number,
};

export default Step4;

const StartAndEndDate = styled.div`
  display: flex;
  gap: 1em;
`;

const LinkToFrontPage = styled.a`
  color: ${(props) => props.theme.color.dark_electric_blue};
  padding: 0 0 0 0.25em;
`;
