export const validateStep = (
  fields,
  step,
  formData,
  validatedSteps,
  setValidatedSteps,
  jobType
) => {
  if (!formData[jobType]) return;

  const validated = Object.entries(formData[jobType])
    .map((e) => {
      if (step !== e[1].step || !e[1].validationRequired) {
        return null;
      }

      if (e[0] === "secondary_workplace") {
        // Specific for Step 5 secondary workplaces - dynamic fields handling
        if (
          formData[jobType].geographic_obligation.value ===
          "geographic_obligation0"
        )
          return null;

        const allDynamicsValid = e[1].value
          .map((arr) => arr.every((e) => e !== ""))
          .every((fields) => fields === true);

        return allDynamicsValid;
      }
      if (e[0] === "wage_supplement_quality") {
        // Specific for Step 2 quality wages - dynamic fields handling
        if (
          formData[jobType].add_wage_supplement_quality.value ===
          "addwagesupplementquality0"
        )
          return null;

        const allDynamicsValid = e[1].value
          .map((arr) => arr.every((e) => e !== ""))
          .every((fields) => fields === true);

        return allDynamicsValid;
      }

      if (e[1].extendedRequired) {
        const { requiredValue, requiredField } = e[1].extendedRequired;
        return requiredValue === formData[jobType][requiredField]?.value &&
          e[1].isValid &&
          e[1].step === step
          ? true
          : null;
      }

      return e[1].isValid && e[1].step === step;
    })
    .filter((e) => e !== null);

  const allValid =
    validated.length === fields ? validated.every((e) => e === true) : false;

  if (allValid) {
    setValidatedSteps({
      ...validatedSteps,
      [jobType]: { ...validatedSteps[jobType], [step]: true },
    });
    return;
  }

  setValidatedSteps({
    ...validatedSteps,
    [jobType]: { ...validatedSteps[jobType], [step]: false },
  });
};
