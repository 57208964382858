import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Label from "elements/Label";
import { useHistory } from "react-router-dom";
import StyledDatePicker from "components/form/DateField";
import SelectField from "components/form/SelectField";
import Headline from "elements/Headline";
import SupportText from "elements/SupportText";
import IndentedSection from "elements/IndentedSection";
import InputField from "components/form/InputField";
import WagesInputField from "components/form/WagesInputField";
import RadioButtonsField from "components/form/RadioButtonsField";
import TextAreaField from "components/form/TextAreaField";
import Modal from "components/Modal";
import useModal from "hooks/useModal";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";
import { validateStep } from "utils/validateStep";
import handleFields from "utils/handleFields";

function Step2({ headline, step }) {
  const {
    formData,
    jobType,
    validatedSteps,
    setValidatedSteps,
    updateDynamicFieldsList,
    dynamicFieldsList,
  } = useContext(FormDataContext);
  const { apiData, labels } = useContext(ApiDataContext);
  const { isVisible, toggleModal, activeModal } = useModal();

  let history = useHistory();

  const redirectToPage = (location) => {
    history.push(location);
  };

  const fieldsWithRequiredValidation = jobType === "individuel" ? 6 : 5;
  const fields = handleFields(
    fieldsWithRequiredValidation,
    step,
    formData[jobType],
    jobType
  );

  useEffect(() => {
    validateStep(
      fields,
      step,
      formData,
      validatedSteps,
      setValidatedSteps,
      jobType
    );
  }, [formData]);

  useEffect(() => {
    // Start with one field by default
    const isChecked =
      formData[jobType]?.add_wage_supplement_quality?.value ===
      "addwagesupplementquality1";
    const isEmpty = dynamicFieldsList[jobType].wage_supplement_quality === 0;

    if (isChecked && isEmpty) {
      updateDynamicFieldsList("wage_supplement_quality", 1);
    }
  }, [formData[jobType]?.add_wage_supplement_quality?.value]);

  useEffect(() => {
    // Start with one field by default
    const isChecked =
      formData[jobType]?.add_wage_supplement_function?.value ===
      "addwagesupplementfunction1";
    const isEmpty = dynamicFieldsList[jobType].wage_supplement_function === 0;

    if (isChecked && isEmpty) {
      updateDynamicFieldsList("wage_supplement_function", 1);
    }
  }, [formData[jobType]?.add_wage_supplement_function?.value]);

  return (
    <>
      <Headline>{headline}</Headline>
      <StyledDatePicker
        step={step}
        name="starttime_of_employment"
        label={labels.starttime_of_employment}
        value={formData[jobType]?.starttime_of_employment?.value}
      />

      <RadioButtonsField
        step={step}
        name="employment_lasting"
        label={labels.employment_lasting}
        options={apiData.employment_lasting}
        value={formData[jobType]?.employment_lasting?.value}
      />
      {formData[jobType]?.employment_lasting?.value === "empchoices0" && (
        <IndentedSection>
          <TextAreaField
            extendedRequired={{
              requiredField: "employment_lasting",
              requiredValue: "empchoices0",
            }}
            step={step}
            name="reason_for_nonlastingemployment"
            label={labels.reason_for_nonlastingemployment}
            value={formData[jobType]?.reason_for_nonlastingemployment?.value}
          />

          <RadioButtonsField
            extendedRequired={{
              requiredField: "employment_lasting",
              requiredValue: "empchoices0",
            }}
            step={step}
            name="employment_lasting_reason"
            label={labels.employment_lasting_reason}
            options={apiData.employment_lasting_reason}
            value={formData[jobType]?.employment_lasting_reason?.value}
          />

          {formData[jobType]?.employment_lasting_reason?.value ===
            "empreasonchoices1" && (
            <IndentedSection>
              <InputField
                extendedRequired={{
                  requiredField: "employment_lasting_reason",
                  requiredValue: "empreasonchoices1",
                }}
                step={step}
                name="limited_to_event"
                label={labels.limited_to_event}
                value={formData[jobType]?.limited_to_event?.value}
              />
            </IndentedSection>
          )}
          {formData[jobType]?.employment_lasting_reason?.value ===
            "empreasonchoices0" && (
            <IndentedSection>
              <StyledDatePicker
                extendedRequired={{
                  requiredField: "employment_lasting_reason",
                  requiredValue: "empreasonchoices0",
                }}
                step={step}
                name="limited_to_date"
                label={labels.limited_to_date}
                value={formData[jobType]?.limited_to_date?.value}
              />
            </IndentedSection>
          )}
        </IndentedSection>
      )}
      {jobType === "individuel" && (
        <>
          <RadioButtonsField
            step={step}
            name="remuneration"
            label={labels.remuneration}
            options={apiData.remuneration}
            value={formData[jobType]?.remuneration?.value}
          />
          <RadioButtonsField
            step={step}
            name="regulated_pay"
            label={labels.regulated_pay}
            options={apiData.regulated_pay}
            value={formData[jobType]?.regulated_pay?.value}
          />
          {formData[jobType]?.regulated_pay?.value === "regulatedpay1" && (
            <IndentedSection>
              <InputField
                extendedRequired={{
                  requiredField: "regulated_pay",
                  requiredValue: "regulatedpay1",
                }}
                step={step}
                name="agreed_pay"
                label={labels.agreed_pay}
                value={formData[jobType]?.agreed_pay?.value}
              />
              <InputField
                extendedRequired={{
                  requiredField: "regulated_pay",
                  requiredValue: "regulatedpay1",
                }}
                step={step}
                name="pay_level"
                label={labels.pay_level}
                value={formData[jobType]?.pay_level?.value}
                validationRequired={
                  formData[jobType]?.pay_level?.validationRequired
                }
              />
              <RadioButtonsField
                extendedRequired={{
                  requiredField: "regulated_pay",
                  requiredValue: "regulatedpay1",
                }}
                step={step}
                name="pay_scale"
                label={labels.pay_scale}
                options={apiData.pay_scale}
                value={formData[jobType]?.pay_scale?.value}
              />
              {formData[jobType]?.pay_scale?.value === "payscale1" && (
                <IndentedSection>
                  <InputField
                    extendedRequired={{
                      requiredField: "pay_scale",
                      requiredValue: "payscale1",
                    }}
                    step={step}
                    name="pay_scale_step"
                    label={labels.pay_scale_step}
                    value={formData[jobType]?.pay_scale_step?.value}
                  />
                  <InputField
                    extendedRequired={{
                      requiredField: "pay_scale",
                      requiredValue: "payscale1",
                    }}
                    step={step}
                    name="pay_scale_wage"
                    label={labels.pay_scale_wage}
                    value={formData[jobType]?.pay_scale_wage?.value}
                  />
                </IndentedSection>
              )}
            </IndentedSection>
          )}
          {formData[jobType]?.regulated_pay?.value === "regulatedpay0" && (
            <>
              <IndentedSection>
                <InputField
                  extendedRequired={{
                    requiredField: "regulated_pay",
                    requiredValue: "regulatedpay0",
                  }}
                  step={step}
                  name="agreed_pay_non_regulated"
                  label={labels.agreed_pay_non_regulated}
                  value={formData[jobType]?.agreed_pay_non_regulated?.value}
                />
                <RadioButtonsField
                  extendedRequired={{
                    requiredField: "regulated_pay",
                    requiredValue: "regulatedpay0",
                  }}
                  step={step}
                  name="wages_besides_pay"
                  label={labels.wages_besides_pay}
                  options={apiData.wages_besides_pay}
                  value={formData[jobType]?.wages_besides_pay?.value}
                />
                {/* TILLÆG 1 */}
                {formData[jobType]?.wages_besides_pay?.value ===
                  "wagesbesidespay1" && (
                  <IndentedSection>
                    <Label legend>Tillæg 1</Label>
                    <RadioButtonsField
                      extendedRequired={{
                        requiredField: "regulated_pay",
                        requiredValue: "regulatedpay0",
                      }}
                      step={step}
                      name="regulated_wage_1"
                      label={labels.regulated_wage}
                      options={apiData.regulated_wage}
                      value={formData[jobType]?.regulated_wage_1?.value}
                    />
                    {formData[jobType]?.regulated_wage_1?.value ===
                      "regulatedwage1" && (
                      <IndentedSection>
                        <InputField
                          extendedRequired={{
                            requiredField: "regulated_wage_1",
                            requiredValue: "regulatedwage1",
                          }}
                          step={step}
                          name="agreed_wage_1"
                          label={labels.agreed_wage}
                          value={formData[jobType]?.agreed_wage_1?.value}
                        />
                        <InputField
                          extendedRequired={{
                            requiredField: "regulated_wage_1",
                            requiredValue: "regulatedwage1",
                          }}
                          step={step}
                          name="pay_level_wage_1"
                          label={labels.pay_level_wage}
                          value={formData[jobType]?.pay_level_wage_1?.value}
                          validationRequired={
                            formData[jobType]?.pay_level_wage_1
                              ?.validationRequired
                          }
                        />
                      </IndentedSection>
                    )}
                    {formData[jobType]?.regulated_wage_1?.value ===
                      "regulatedwage0" && (
                      <IndentedSection>
                        <InputField
                          extendedRequired={{
                            requiredField: "regulated_wage_1",
                            requiredValue: "regulatedwage0",
                          }}
                          step={step}
                          name="agreed_wage_1"
                          label={labels.agreed_wage}
                          value={formData[jobType]?.agreed_wage_1?.value}
                        />
                      </IndentedSection>
                    )}
                  </IndentedSection>
                )}
                {/* TILLÆG 2 */}
                {formData[jobType]?.wages_besides_pay?.value ===
                  "wagesbesidespay1" && (
                  <IndentedSection>
                    <Label legend>Tillæg 2</Label>
                    <RadioButtonsField
                      extendedRequired={{
                        requiredField: "regulated_pay",
                        requiredValue: "regulatedpay0",
                      }}
                      step={step}
                      name="regulated_wage_2"
                      label={labels.regulated_wage}
                      options={apiData.regulated_wage}
                      value={formData[jobType]?.regulated_wage_2?.value}
                    />
                    {formData[jobType]?.regulated_wage_2?.value ===
                      "regulatedwage1" && (
                      <IndentedSection>
                        <InputField
                          extendedRequired={{
                            requiredField: "regulated_wage_2",
                            requiredValue: "regulatedwage1",
                          }}
                          step={step}
                          name="agreed_wage_2"
                          label={labels.agreed_wage}
                          value={formData[jobType]?.agreed_wage_2?.value}
                        />
                        <InputField
                          extendedRequired={{
                            requiredField: "regulated_wage",
                            requiredValue: "regulatedwage1",
                          }}
                          step={step}
                          name="pay_level_wage_2"
                          label={labels.pay_level_wage}
                          value={formData[jobType]?.pay_level_wage_2?.value}
                          validationRequired={
                            formData[jobType]?.pay_level_wage_2
                              ?.validationRequired
                          }
                        />
                      </IndentedSection>
                    )}
                    {formData[jobType]?.regulated_wage_2?.value ===
                      "regulatedwage0" && (
                      <IndentedSection>
                        <InputField
                          extendedRequired={{
                            requiredField: "regulated_wage_2",
                            requiredValue: "regulatedwage0",
                          }}
                          step={step}
                          name="agreed_wage_2"
                          label={labels.agreed_wage}
                          value={formData[jobType]?.agreed_wage_2?.value}
                        />
                      </IndentedSection>
                    )}
                  </IndentedSection>
                )}
              </IndentedSection>
            </>
          )}
        </>
      )}
      <InputField
        step={step}
        name="weekly_hours"
        label={labels.weekly_hours[jobType] ?? labels.weekly_hours.default}
        value={formData[jobType]?.weekly_hours?.value}
      />
      {jobType !== "individuel" &&
        jobType !== "gartner" &&
        jobType !== "gartneriarbejder" &&
        jobType !== "hk" && (
          <SupportText>{labels.supportTexts.weekly_hours}</SupportText>
        )}
      <RadioButtonsField
        step={step}
        name="trialAgreement"
        label={labels.trialAgreement}
        options={apiData.trialAgreement}
        value={formData[jobType]?.trialAgreement?.value}
      />
      {jobType === "individuel" && (
        <SupportText>{labels.supportTexts.holiday_law}</SupportText>
      )}
      {jobType !== "individuel" && (
        <RadioButtonsField
          step={step}
          name="official_pension"
          label={labels.official_pension[jobType]}
          options={apiData.official_pension}
          value={formData[jobType]?.official_pension?.value}
        />
      )}
      {formData[jobType]?.official_pension?.value === "officialpension1" && (
        <IndentedSection>
          <Label>
            {labels.supportTexts.individual_hiring}
            <LinkToFrontPage href="" onClick={() => redirectToPage("/")}>
              Gå til forsiden
            </LinkToFrontPage>
          </Label>
        </IndentedSection>
      )}
      {formData[jobType]?.official_pension?.value === "officialpension0" && (
        <>
          {(jobType === "graver" || jobType === "kirketjener") && (
            <>
              <RadioButtonsField
                step={step}
                name="hired_thirtydays"
                extendedRequired={{
                  requiredField: "official_pension",
                  requiredValue: "officialpension0",
                }}
                label={labels.hired_thirtydays[jobType]}
                options={apiData.hired_thirtydays}
                value={formData[jobType]?.hired_thirtydays?.value}
              />
              {formData[jobType]?.hired_thirtydays?.value ===
                "hiredthirty0" && (
                <>
                  {jobType === "graver" && (
                    <>
                      <RadioButtonsField
                        step={step}
                        name="amu"
                        extendedRequired={{
                          requiredField: "hired_thirtydays",
                          requiredValue: "hiredthirty0",
                        }}
                        label={labels.amu}
                        options={apiData.amu}
                        value={formData[jobType]?.amu?.value}
                      />
                    </>
                  )}
                  <InputField
                    step={step}
                    name="pay"
                    extendedRequired={{
                      requiredField: "hired_thirtydays",
                      requiredValue: "hiredthirty0",
                    }}
                    label={labels.pay}
                    value={formData[jobType]?.pay?.value}
                    toggleModal={toggleModal}
                  />
                  <SupportText>
                    {labels.supportTexts.pay[0]}
                    <br /> {labels.supportTexts.pay[1]}
                  </SupportText>
                </>
              )}
            </>
          )}
          {/* ANSÆTTELSE LOKATION */}
          {(jobType === "gartner" ||
            jobType === "gartneriarbejder" ||
            jobType === "hk") && (
            <>
              <RadioButtonsField
                step={step}
                name="employment_location"
                extendedRequired={{
                  requiredField: "official_pension",
                  requiredValue: "officialpension0",
                }}
                label={labels.employment_location}
                options={apiData.employment_location}
                value={formData[jobType]?.employment_location?.value}
              />
            </>
          )}
          {/* INPLACERING AF MÅNEDSLØN */}
          {jobType === "hk" &&
            formData[jobType]?.employment_location?.value !== "" && (
              <>
                {formData[jobType]?.employment_location?.value ===
                  "employmentlocation0" && (
                  <SelectField
                    step={step}
                    name="monthly_pay_step_hk_grp1"
                    extendedRequired={{
                      requiredField: "employment_location",
                      requiredValue: "employmentlocation0",
                    }}
                    label={labels.monthly_pay_step_hk_grp1}
                    options={apiData.monthly_pay_step_hk_grp1}
                    value={formData[jobType]?.monthly_pay_step_hk_grp1?.value}
                  />
                )}
                {formData[jobType]?.employment_location?.value ===
                  "employmentlocation1" && (
                  <SelectField
                    step={step}
                    name="monthly_pay_step_hk_grp1"
                    extendedRequired={{
                      requiredField: "employment_location",
                      requiredValue: "employmentlocation1",
                    }}
                    label={labels.monthly_pay_step_hk_grp1}
                    options={apiData.monthly_pay_step_hk_grp1}
                    value={formData[jobType]?.monthly_pay_step_hk_grp1?.value}
                  />
                )}
                {formData[jobType]?.monthly_pay_step_hk_grp1?.value ===
                  "monthlypaystephk0" &&
                  formData[jobType]?.employment_location?.value ===
                    "employmentlocation0" && (
                    <StyledDatePicker
                      step={step}
                      name="advancement_date_step2"
                      label={labels.advancement_date_step2}
                      value={formData[jobType]?.advancement_date_step2?.value}
                    />
                  )}
                <SelectField
                  step={step}
                  name="monthly_pay_step_hk_grp2"
                  extendedRequired={{
                    requiredField: "official_pension",
                    requiredValue: "officialpension0",
                  }}
                  label={labels.monthly_pay_step_hk_grp2}
                  options={apiData.monthly_pay_step_hk_grp2}
                  value={formData[jobType]?.monthly_pay_step_hk_grp2?.value}
                />
                <SelectField
                  step={step}
                  name="monthly_pay_step_hk_grp3"
                  extendedRequired={{
                    requiredField: "official_pension",
                    requiredValue: "officialpension0",
                  }}
                  label={labels.monthly_pay_step_hk_grp3}
                  options={apiData.monthly_pay_step_hk_grp3}
                  value={formData[jobType]?.monthly_pay_step_hk_grp3?.value}
                />
              </>
            )}
          {/* MÅNEDSLØN BASISTRIN */}
          {(jobType === "gartner" || jobType === "gartneriarbejder") && (
            <>
              <StyledDatePicker
                step={step}
                name="experience_date"
                label={labels.experience_date}
                subLabel={labels.supportTexts.experience_date}
                value={formData[jobType]?.experience_date?.value}
              />
            </>
          )}
          {/* LØN TILLÆG */}
          {(jobType === "gartner" ||
            jobType === "gartneriarbejder" ||
            jobType === "hk") && (
            <>
              <RadioButtonsField
                step={step}
                extendedRequired={{
                  requiredField: "official_pension",
                  requiredValue: "officialpension0",
                }}
                name="add_wage_supplement_quality"
                label={labels.add_wage_supplement_quality}
                options={apiData.add_wage_supplement_quality}
                value={formData[jobType]?.add_wage_supplement_quality?.value}
              />
              {formData[jobType]?.add_wage_supplement_quality?.value ===
                "addwagesupplementquality1" && (
                <IndentedSection>
                  <Label>{labels.wage_supplement_quality}</Label>
                  <WagesInputField
                    step={step}
                    name="wage_supplement_quality"
                    label={labels.wage_supplement_quality}
                    value={formData[jobType]?.wage_supplement_quality?.value}
                  />
                </IndentedSection>
              )}
              <RadioButtonsField
                step={step}
                extendedRequired={{
                  requiredField: "official_pension",
                  requiredValue: "officialpension0",
                }}
                name="add_wage_supplement_function"
                label={labels.add_wage_supplement_function}
                options={apiData.add_wage_supplement_function}
                value={formData[jobType]?.add_wage_supplement_function?.value}
              />
              {formData[jobType]?.add_wage_supplement_function?.value ===
                "addwagesupplementfunction1" && (
                <IndentedSection>
                  <Label>{labels.wage_supplement_function}</Label>
                  <WagesInputField
                    step={step}
                    name="wage_supplement_function"
                    label={labels.wage_supplement_function}
                    value={formData[jobType]?.wage_supplement_function?.value}
                  />
                </IndentedSection>
              )}
            </>
          )}
        </>
      )}
      <Modal
        isVisible={isVisible && activeModal === "pay"}
        hideModal={toggleModal}
      >
        <h3>Intervalløn</h3>
        {jobType === "kirketjener" ? (
          <>
            <IntervalPay>
              <PayGroup>Løngruppe 1</PayGroup>
              <Price>kr. 249.025 – kr. 321.112, fikspunkt kr. 249.025</Price>
            </IntervalPay>
          </>
        ) : (
          <>
            <IntervalPay>
              <Category>Øvrige land:</Category>
              <PayGroup>Løngruppe 1</PayGroup>
              <Price>kr. 269.025 – kr. 321.112, fikspunkt kr. 269.025</Price>
            </IntervalPay>
            <IntervalPay>
              <Category>Hovedstad:</Category>
              <PayGroup>Løngruppe 1</PayGroup>
              <Price>kr. 269.025 – kr. 321.112, fikspunkt kr. 272.957</Price>
            </IntervalPay>
            <IntervalPay>
              <Category>Øvrige land:</Category>
              <PayGroup>Løngruppe 2</PayGroup>
              <Price>kr. 262.132 – kr. 334.218, fikspunkt kr. 275.239</Price>
            </IntervalPay>
            <IntervalPay>
              <Category>Hovedstad:</Category>
              <PayGroup>Løngruppe 2</PayGroup>
              <Price>kr. 262.132 – kr. 334.218, fikspunkt kr. 288.345</Price>
            </IntervalPay>
          </>
        )}
      </Modal>
    </>
  );
}

const LinkToFrontPage = styled.a`
  color: ${(props) => props.theme.color.dark_electric_blue};
  padding: 0 0 0 0.25em;
`;

const IntervalPay = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25em 0;
`;

const Category = styled.div`
  font-size: 0.85em;
  width: 100px;
`;

const PayGroup = styled.div`
  font-size: 0.85em;
  width: 100px;
`;

const Price = styled.div`
  font-size: 0.85em;
`;

Step2.propTypes = {
  headline: PropTypes.string,
  step: PropTypes.number,
};

export default Step2;
