import styled from "styled-components";

const HelpText = styled.div`
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 1em 0;
  max-width: 300px;
  max-width: ${(props) => (props.wide ? "600px" : "300px")};
`;

export default HelpText;
