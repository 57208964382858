const handleStepTwo = (defaultFields, step, formData, jobType) => {
  let fields = defaultFields;

  if (formData.employment_lasting?.value === "empchoices0") {
    fields += 2;
    if (
      formData.employment_lasting_reason?.value === "empreasonchoices0" ||
      formData.employment_lasting_reason?.value === "empreasonchoices1"
    ) {
      fields += 1;
    }
  }

  if (formData.employment_location?.value !== "" && jobType === "hk") {
    fields += 3;
  }

  if (formData.regulated_pay?.value === "regulatedpay1") {
    fields += 2;
  }

  if (formData.regulated_pay?.value === "regulatedpay0") {
    fields += 2;
  }

  if (formData.wages_besides_pay?.value === "wagesbesidespay1") {
    fields += 2;
  }

  if (formData.regulated_wage_1?.value === "regulatedwage1") {
    fields += 1;
  }

  if (formData.regulated_wage_1?.value === "regulatedwage0") {
    fields += 1;
  }

  if (formData.regulated_wage_2?.value === "regulatedwage1") {
    fields += 1;
  }

  if (formData.regulated_wage_2?.value === "regulatedwage0") {
    fields += 1;
  }

  if (formData.pay_scale?.value === "payscale1") {
    fields += 2;
  }

  if (formData.monthly_pay_step_hk_grp1?.value === "monthlypaystephk0") {
    fields += 1;
  }

  if (formData.official_pension?.value === "officialpension0") {
    switch (jobType) {
      case "graver":
        fields += 1;
        break;
      case "gartner":
      case "gartneriarbejder":
        fields += 4;
        break;
      case "hk":
        fields += 3;
        break;
      case "kirketjener":
        fields += 1;
        break;
    }
  }

  if (
    formData.add_wage_supplement_quality?.value === "addwagesupplementquality1"
  ) {
    fields += 1;
  }

  if (
    formData.add_wage_supplement_function?.value ===
    "addwagesupplementfunction1"
  ) {
    fields += 1;
  }

  if (formData.hired_thirtydays?.value === "hiredthirty0") {
    switch (jobType) {
      case "graver":
        fields += 2;
        break;
      case "kirketjener":
        fields += 1;
        break;
      default:
        fields += 2;
        break;
    }
  }

  return fields;
};

const handleStepThree = (defaultFields, step, formData) => {
  let fields = defaultFields;

  if (formData.add_candidateNotes?.value === "addcandidatenotes1") {
    fields += 1;
  }

  return fields;
};

const handleStepFour = (defaultFields, step, formData) => {
  let fields = defaultFields;

  if (formData.age70?.value === "age70_1") {
    fields += 1;
    if (formData.handlePension?.value === "handlePension_1") {
      fields += 1;
    }
  }

  if (formData.age70?.value === "age70_0") {
    fields += 1;
    if (formData.age25_atleast4years?.value === "age25_atleast4years1") {
      fields += 2;
    }

    if (formData.age25_atleast4years?.value === "age25_atleast4years0") {
      fields += 1;
      if (formData.age20_atleast9months?.value === "age20_atleast9months1") {
        fields += 3;
      }

      if (formData.age20_atleast9months?.value === "age20_atleast9months0") {
        fields += 1;
        if (formData.age20_payed_pension?.value === "age20_payed_pension1") {
          fields += 3;
        }

        if (formData.age20_payed_pension?.value === "age20_payed_pension0") {
          fields += 5;
        }
      }
    }
  }

  return fields;
};

const handleStepFive = (defaultFields, step, formData, jobType) => {
  let fields = defaultFields;

  if (formData.geographic_obligation?.value === "geographic_obligation1") {
    fields += 1;
  }

  if (
    formData.availability_surcharge?.value === "availability_surcharge1" &&
    jobType !== "kirketjener"
  ) {
    fields += 1;
  }

  return fields;
};

const handleStepSix = (defaultFields, step, formData) => {
  let fields = defaultFields;

  if (
    formData.workScheduleHasAdditionalNotes?.value ===
    "workScheduleHasAdditionalNotes1"
  ) {
    fields += 1;
  }

  if (formData.deals?.value === "deals1") {
    fields += 1;
  }
  return fields;
};

const handleExtensions = (defaultFields, step, formData, jobType) => {
  if (!formData) return;

  // Collect steps with validations
  switch (step) {
    case 1:
      return defaultFields;
    case 2:
      return handleStepTwo(defaultFields, step, formData, jobType);
    case 3:
      return handleStepThree(defaultFields, step, formData, jobType);
    case 4:
      return handleStepFour(defaultFields, step, formData, jobType);
    case 5:
      return handleStepFive(defaultFields, step, formData, jobType);
    case 6:
      return handleStepSix(defaultFields, step, formData, jobType);
    default:
      defaultFields;
  }
};

export default handleExtensions;
