import React, { useContext } from "react";
import Layout from "components/Layout";
import { useParams } from "react-router-dom";
import StepNavigation from "components/StepNavigation";
import FormStep from "components/FormStep";
import { FormDataContext } from "context/FormDataContext";
import { ApiDataContext } from "context/ApiDataContext";
import styled from "styled-components";
import useModal from "hooks/useModal";
import PrintModal from "components/Modals/Print";
import Button from "elements/Button";

function Apron() {
  const {
    currentStep,
    updateStep,
    jobType,
    emptyFieldsCurrentStep,
  } = useContext(FormDataContext);
  const { steps } = useContext(ApiDataContext);
  const { isVisible, toggleModal, activeModal } = useModal();

  let { jobTitle } = useParams();
  const maxSteps = steps[jobType]?.maxSteps ?? steps.default.maxSteps;
  let nextStep = currentStep + 1;
  let prevStep = currentStep - 1;

  if (jobType === "individuel" && currentStep === 2) {
    nextStep = 5;
  }
  if (jobType === "individuel" && currentStep === 5) {
    prevStep = 2;
  }

  if (
    (jobType === "gartner" ||
      jobType === "gartneriarbejder" ||
      jobType === "hk") &&
    currentStep === 3
  ) {
    nextStep = 5;
  }
  if (
    (jobType === "gartner" ||
      jobType === "gartneriarbejder" ||
      jobType === "hk") &&
    currentStep === 5
  ) {
    prevStep = 3;
  }

  return (
    <Layout jobTitle={jobTitle} showToolBar>
      <StepNavigation />
      <FormStep />
      <ResetStepBtn
        onClick={() => {
          const r = confirm("Er du sikker?");
          if (r) emptyFieldsCurrentStep();
        }}
      >
        Nulstil felter
      </ResetStepBtn>
      <BtnNavigation>
        {currentStep > 0 && (
          <NavBtn type="button" onClick={() => updateStep(prevStep)}>
            &lt; Forrige
          </NavBtn>
        )}
        {currentStep < maxSteps && (
          <NavBtn type="button" onClick={() => updateStep(nextStep)}>
            Næste &gt;
          </NavBtn>
        )}
        {currentStep === maxSteps && (
          <NavBtn type="button" onClick={() => toggleModal("print")}>
            Udskriv
          </NavBtn>
        )}
      </BtnNavigation>
      <PrintModal
        jobType={jobType}
        isVisible={isVisible}
        toggleModal={toggleModal}
        activeModal={activeModal}
      />
    </Layout>
  );
}

const NavBtn = styled.button`
  border-radius: 8px;
  background: ${(props) => props.theme.color.light_sea_green};
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: 0.3s background;
  &:only-child {
    margin-left: auto;
  }
  &:hover {
    background: ${(props) => props.theme.color.dark_electric_blue};
  }
  &:focus {
    outline: none;
  }
`;

const ResetStepBtn = styled(Button)`
  background: ${(props) => props.theme.color.dark_electric_blue};
  margin: 1em 0 0 0;
`;

const BtnNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5em 0;
`;

export default Apron;
