import React from "react";
import Modal from "components/Modal";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ListOfUnvalidatedSteps from "components/ListOfUnavailableSteps";

function PrintModal({ jobType, isVisible, toggleModal, activeModal }) {
  return (
    <Modal
      isVisible={isVisible && activeModal === "print"}
      hideModal={toggleModal}
    >
      <h3>Du kan udskrive en komplet oversigt over alle felter i forklædet</h3>
      <ListOfUnvalidatedSteps hideModal={toggleModal} />
      <LinkButton to={`/print/` + jobType} target="_blank">
        Udskriv
      </LinkButton>
    </Modal>
  );
}

PrintModal.propTypes = {
  jobType: PropTypes.string,
  activeModal: PropTypes.string,
  isVisible: PropTypes.bool,
  toggleModal: PropTypes.func,
};

const LinkButton = styled(Link)`
  color: #fff;
  background: ${(props) => props.theme.color.light_sea_green};
  font-size: 12px;
  text-decoration: none;
  font-weight: 400;
  border-radius: 10px;
  padding: 3px 10px;
  border: 0;
  margin: 1em;
  transition: 0.5s background;
  cursor: pointer;
  &:first-child {
    margin: 1em 1em 1em 0;
  }
  &:last-child {
    margin: 1em 0 2em 1em;
  }
  &:hover {
    background: ${(props) => props.theme.color.dark_electric_blue};
  }
`;

export default PrintModal;
